import { Card } from "@mui/material";
import { animateGradient } from "assets/theme/base/animations";
import XBox from "components/XBox";
import { useXplainableController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useSettingTab } from "hooks";
import {
  AnalystAppBar,
  ProgressBlock,
  SettingTab,
  UploadDataset,
} from "layouts/autotrain/components";
import { useAutoTrainContent } from "layouts/autotrain/components/AutoTrainContent/useAutoTrainContent";
import React, { useState } from "react";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import { AnalystChat, AnalystSavedPlots } from "./components";
import { DataTable } from "layouts/autotrain/components/DataTable";

export const Analyst = () => {
  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;
  const [analystTabValue, setAnalystTabValue] = useState(0);
  const [isOpenSavedPlots, setIsOpenSavedPlots] = useState(false);

  const {
    isModalOpen,
    handleClose,
    modelSettings,
    modelHandler,
    modelProviderHandler,
    maxTokenHandler,
    temperatureHandler,
    frequencyPenaltyHandler,
    presencePenaltyHandler,
  } = useSettingTab();

  const {
    uploadSuccessful,
    isLoading,
    isDataLoading,
    uploadProgress,
    fileNames,
    getRootProps,
    getInputProps,
    onDropAccepted,
    setDataSetName,
    fileMetadata,
    activeStep
  } = useAutoTrainContent(modelSettings);

  return (
    <DashboardLayout
      sx={{
        backgroundImage: () =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title="Analyst"
        description={
          "One-click shareable reports with intuitive drag-and-drop functionality"
        }
        icon={"ni ni-atom"}
      />

      <AnalystAppBar
        onAnalystTab={setAnalystTabValue}
        analystTabValue={analystTabValue}
        setIsOpenSavedPlots={setIsOpenSavedPlots}
        isAnalyst
      />
      <ProgressBlock
        activeStep={activeStep}
        fileNames={fileNames}
        fileMetadata={fileMetadata}
      />

      
      <XBox width="100%">
        {fileMetadata && fileMetadata.length > 0 && activeStep < 2 && <DataTable fileMetadata={fileMetadata} />}
      </XBox>

      {analystTabValue === 0 && activeStep < 1 &&(
        <Card sx={{ padding: "16px", mt: 2 }}>
          <UploadDataset
            fileNames={fileNames}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            onDropAccepted={onDropAccepted}
            setDataSetName={setDataSetName}
          />
        </Card>
      )}

      {analystTabValue === 0 && activeStep > 1 && (
        <XBox display="flex" flexDirection="column" gap={3} mt={2}>
            <AnalystChat
              modelSettings={modelSettings}
              uploadSuccessful={uploadSuccessful}
              activeStep={activeStep}
          />
        </XBox>
      )}

      {isOpenSavedPlots && (
        <AnalystSavedPlots setIsOpenSavedPlots={setIsOpenSavedPlots} />
      )}

      <XBox>
        {analystTabValue === 1 && (
          <SettingTab
            isModalOpen={isModalOpen}
            handleClose={handleClose}
            modelSettings={modelSettings}
            modelProviderHandler={modelProviderHandler}
            modelHandler={modelHandler}
            maxTokenHandler={maxTokenHandler}
            temperatureHandler={temperatureHandler}
            frequencyPenaltyHandler={frequencyPenaltyHandler}
            presencePenaltyHandler={presencePenaltyHandler}
          />
        )}
      </XBox>
    </DashboardLayout>
  );
};
