import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import configs from "./config";
import XDatePicker from "components/XDatePicker";
import { Tooltip, AppBar, Tabs, Tab } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXPanelQuery } from "api/query";
import { useXplainableController } from "context";
import rgba from "assets/theme/functions/rgba";
import colors from "assets/theme/base/colors";
import { useAuth0 } from "@auth0/auth0-react";
import XSelect from "components/XSelect";

const getInitialDateRange = () => {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  const formatDate = (date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}`;
  return [formatDate(sevenDaysAgo), formatDate(today)];
};

// Opaque overlay styling function
const getOverlayStyle = (darkMode) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: rgba(darkMode ? colors.dark.main : colors.light.main, 0.5),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
});

function ProgressLineChart({
  color,
  icon,
  title,
  height,
  event,
  group = "count",
  shadow = true,
  stack = false,
  ...queryProps
}) {
  const { logout } = useAuth0();
  const { activeSubscription } = useApiKey();
  const containerRef = useRef();
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const [dateRange, setDateRange] = useState(getInitialDateRange());
  const [fromDate, setFromDate] = useState(getInitialDateRange()[0]);
  const [toDate, setToDate] = useState(getInitialDateRange()[1]);
  const [uniqueViews, setUniqueViews] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(1);
  const [tableData, setTableData] = useState(null);
  const animationDuration = useRef(1000);

  let total = null;
  let lineData = null;
  let lineOptions = null;

  if (!activeSubscription) {
    color = "secondary";
  }

  const emptyData = {
    labels: [0, 0, 0, 0, 0, 0, 0],
    data: [0, 0, 0, 0, 0, 0, 0],
  };

  const handleUniqueViews = (obj) => {
    if (obj.value === "all") {
      setUniqueViews(false);
      return;
    }
    setUniqueViews(true);
  };

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const getUnitByTabValue = (value) => {
    switch (value) {
      case 0:
        return "hour";
      case 1:
        return "day";
      case 2:
        return "month";
      default:
        return "day";
    }
  };

  const query = useXPanelQuery(
    {
      from_date: fromDate,
      to_date: toDate,
      unit: getUnitByTabValue(tabValue),
      type: uniqueViews ? "unique" : "general",
      event: event,
      ...queryProps,
    },
    logout
  );

  const { data, isLoading, isError } = query;

  console.log("The data is", data);

  useEffect(() => {
    if (data) {
      setTableData(data);
      animationDuration.current = 1000;
    }
  }, [data]);

  // Get the total/average to display
  const counts = tableData && tableData.data.data.values[event];
  if (counts) {
    const values = Object.values(counts);
    if (group === 'average') {
      const nonZeroValues = values.filter(v => v !== 0);
      total = nonZeroValues.length > 0
        ? nonZeroValues.reduce((acc, curr) => acc + curr, 0) / nonZeroValues.length
        : 0;
    } else {
      total = values.reduce((acc, curr) => acc + curr, 0);
    }
  }

  // Pass group to transformData
  const transformedData = tableData && transformData(tableData.data, event);

  // Put into correct format for chart
  const chartConfig = configs(
    color,
    transformedData?.labels || emptyData.labels,
    title,
    transformedData?.data || emptyData.data,
    animationDuration.current
  );

  // Set the line data and options
  lineData = chartConfig.data;
  lineOptions = chartConfig.options;

  if (animationDuration.current) {
    animationDuration.current = 0;
  }

  return (
    <Card sx={{ p:1, boxShadow: shadow ? undefined : "none", height: "380px", position: "relative" }} ref={containerRef}>
      {/* Opaque Overlay for Error - covers full card */}
      {isError && (
        <XBox sx={getOverlayStyle(darkMode)} flexDirection="column">
          <XTypography variant="h6" color={darkMode ? "white" : "black"}>
            {"We're having trouble fetching the data for this plot."}
          </XTypography>
          <XTypography variant="body1" color={"secondary"} fontSize="16px">
            {"Please try again later."}
          </XTypography>

        </XBox>
      )}

      {/* Main Content */}
      <XBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        pt={2}
        px={stack ? 1 : 2}
      >
        <XBox lineHeight={1} display="flex" justifyContent="space-between" width="100%">
          <XTypography
            variant="button"
            fontWeight="medium"
            textTransform="capitalize"
            fontSize="18px"
          >
            {title}
          </XTypography>

          <XDatePicker
            value={dateRange}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
            }}
            onChange={(dates) => {
              setDateRange(dates);
              if (dates && dates.length === 2) {
                const formatDate = (date) => {
                  const d = new Date(date);
                  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(
                    d.getDate()
                  ).padStart(2, "0")}`;
                };
                setFromDate(formatDate(dates[0]));
                setToDate(formatDate(dates[1]));
              }
            }}
          />
        </XBox>
        <XBox display="flex" sx={{ alignSelf: "start" }}>
          <XTypography variant="h5" fontWeight="bold" fontSize="24px" color={color}>
            {isLoading || isError ? 0 : total}
          </XTypography>
        </XBox>
        <XBox display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <XBox>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{
                  "& .Mui-selected": {
                    color: `${colors[color].main} !important`,
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: colors[color].main,
                  },
                }}
              >
                <Tooltip title="Show hourly count">
                  <Tab label="Hour" sx={{ minWidth: "auto", padding: 0, marginRight: 0, fontSize: "16px" }} />
                </Tooltip>
                <Tooltip title="Show daily count">
                  <Tab label="Day" sx={{ minWidth: "auto", width: "fit-content", padding: 0, marginRight: 0, fontSize: "16px" }} />
                </Tooltip>
                <Tooltip title="Show monthly count">
                  <Tab label="Month" sx={{ minWidth: "auto", width: "fit-content", padding: 0, fontSize: "16px" }} />
                </Tooltip>
              </Tabs>
            </AppBar>
          </XBox>
          <XBox display="flex" alignItems="center">
            <XSelect
              defaultValue={{ value: "all", label: "All Views" }}
              options={[
                { value: "unique", label: "Unique Views" },
                { value: "all", label: "All Views" },
              ]}
              size="small"
              onChange={handleUniqueViews}
            />
          </XBox>
        </XBox>
      </XBox>

      <XBox mt={2}>
        {lineData !== null && lineOptions !== null && (
          <Line data={lineData} style={{ height: "200px", width: "100%" }} options={lineOptions} />
        )}
      </XBox>
    </Card>
  );
}

ProgressLineChart.defaultProps = {
  color: "info",
  height: "6.25rem",
};

ProgressLineChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "xppink",
    "xpblue",
    "xpgreen",
    "xppurple",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array),
  event: PropTypes.string,
  shadow: PropTypes.bool,
  stack: PropTypes.bool,
  group: PropTypes.oneOf(['count', 'average']),
};

export default React.memo(ProgressLineChart);

export function transformData(inputData, event) {
  if (!inputData || !inputData.data || !inputData.data.values || !inputData.data.values[event]) {
    console.error("Invalid inputData format");
    return {
      labels: [],
      data: [],
    };
  }

  // Extracting series from the keys of the event object
  const series = Object.keys(inputData.data.values[event]);
  const values = inputData.data.values[event];
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Check if all the dates are from the same day
  const sameDay = series.every(
    (date) => new Date(date).toDateString() === new Date(series[0]).toDateString()
  );

  // Check if all the dates are from the same month
  const sameMonth = series.every(
    (date) => new Date(date).getMonth() === new Date(series[0]).getMonth()
  );

  let labels;

  if (sameDay) {
    labels = series.map((date) => {
      const d = new Date(date);
      return `${d.getDate()}-${d.getHours()}`;
    });
  } else if (sameMonth) {
    labels = series.map((date) => {
      const d = new Date(date);
      return `${monthNames[d.getMonth()]}-${d.getDate()}`;
    });
  } else {
    labels = series.map((date) => monthNames[new Date(date).getMonth()]);
  }

  // Simply map the values directly now
  const data = series.map(date => values[date]);

  return {
    labels: labels,
    data: data,
  };
}
