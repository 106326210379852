import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { usePipeLineQuery } from "api/query";

const initialAutoTrainContext = {
  tabValue: 0,
  setTabValue: () => {},
  goalData: [],
  setGoalData: () => {},
  summarizeData: [],
  setSummarizeData: () => {},
  fileMetadata: [],
  setFileMetadata: () => {},
  recommendations: [],
  setRecommendations: () => {},
  featureEngineeringData: [],
  setFeatureEngineeringData: () => {},
  activeStep: 0,
  setActiveStep: () => {},
  isDataLoading: false,
  setIsDataLoading: () => {},
  activeGoal: "",
  setActiveGoal: () => {},
  chatHistory: [],
  setChatHistory: () => {},
  isSuggestionVisible: true,
  setIsSuggestionVisible: () => {},
  inputValue: "",
  setInputValue: () => {},
  checkedState: [],
  setCheckedState: () => {},
  selectedPredictionLabel: null,
  setSelectedPredictionLabel: () => {},
  titlesArray: [],
  setTitlesArray: () => {},
  uploadSuccessful: false,
  setUploadSuccessful: () => {},
  isLoading: false,
  setIsLoading: () => {},
  setIsChatSuggestionVisible: () => {},
  isChatSuggestionVisible: true,
  savedPlots: [],
  setSavedPlots: () => {},
  isAnalystOpen: false,
  setIsAnalystOpen: () => {},
  setPreprocessor: () => {},
  preprocessor: "",
  finalTableData: {},
  setFinalTableData: () => {},
  setColumnKeys: () => [],
  columnKeys: {},
  partition: null,
  setPartition: () => {},
  summaryMetrics: {},
  setSummaryMetrics: () => {},
};

export const AutoTrainContext = createContext(initialAutoTrainContext);

export const AutoTrainProvider = ({ children }) => {

  const [tabValue, setTabValue] = useState(0);
  const [goalData, setGoalData] = useState([]);
  const [summarizeData, setSummarizeData] = useState([]);
  const [fileMetadata, setFileMetadata] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [featureEngineeringData, setFeatureEngineeringData] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [columnKeys, setColumnKeys] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isSuggestionVisible, setIsSuggestionVisible] = useState(true);
  const [isChatSuggestionVisible, setIsChatSuggestionVisible] = useState(true);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [activeGoal, setActiveGoal] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [finalTableData, setFinalTableData] = useState(null);
  const [preprocessor, setPreprocessor] = useState({ id: "", version: "" });
  const [summaryMetrics, setSummaryMetrics] = useState({})

  //Train step state
  const [checkedState, setCheckedState] = useState([]);
  const [selectedPredictionLabel, setSelectedPredictionLabel] = useState(null);
  const [titlesArray, setTitlesArray] = useState([]);

  const [savedPlots, setSavedPlots] = useState([]);
  const [isAnalystOpen, setIsAnalystOpen] = useState(false);

  const [partition, setPartition] = useState(null);

  const location = useLocation();

  const abortController = new AbortController();
  const signal = abortController.signal;

  const {
    data: pipeLineData,
    isFetching: isPipeLineLoading,
    refetch: pipeLineRefetch,
  } = usePipeLineQuery(preprocessor?.version);

  useEffect(() => {
    // Reset context state when the route changes
    setTabValue(0);
    setGoalData([]);
    setSummarizeData([]);
    setFileMetadata([]);
    setRecommendations([]);
    setFeatureEngineeringData([]);
    setChatHistory([]);
    setIsDataLoading(false);
    setIsSuggestionVisible(true);
    setIsChatSuggestionVisible(true);
    setUploadSuccessful(false);
    setIsLoading(false);
    setActiveStep(0);
    setActiveGoal("");
    setInputValue("");
    setCheckedState([]);
    setSelectedPredictionLabel(null);
    setTitlesArray([]);
    setSavedPlots([]);
    setIsAnalystOpen(false);
    setPartition(null);
  }, [location]);

  return (
    <AutoTrainContext.Provider
      value={{
        tabValue,
        setTabValue,
        goalData,
        setGoalData,
        summarizeData,
        setSummarizeData,
        fileMetadata,
        setFileMetadata,
        recommendations,
        setRecommendations,
        featureEngineeringData,
        setFeatureEngineeringData,
        activeStep,
        setActiveStep,
        isDataLoading,
        setIsDataLoading,
        activeGoal,
        setActiveGoal,
        chatHistory,
        setChatHistory,
        isSuggestionVisible,
        setIsSuggestionVisible,
        inputValue,
        setInputValue,
        checkedState,
        setCheckedState,
        selectedPredictionLabel,
        setSelectedPredictionLabel,
        titlesArray,
        setTitlesArray,
        uploadSuccessful,
        setUploadSuccessful,
        isLoading,
        setIsLoading,
        isChatSuggestionVisible,
        setIsChatSuggestionVisible,
        savedPlots,
        setSavedPlots,
        isAnalystOpen,
        setIsAnalystOpen,
        preprocessor,
        setPreprocessor,
        pipeLineData,
        isPipeLineLoading,
        pipeLineRefetch,
        isApplyLoading,
        setIsApplyLoading,
        finalTableData,
        setFinalTableData,
        setColumnKeys,
        columnKeys,
        partition,
        setPartition,
        summaryMetrics,
        setSummaryMetrics
      }}
    >
      {children}
    </AutoTrainContext.Provider>
  );
};

AutoTrainProvider.propTypes = {
  children: PropTypes.node,
};