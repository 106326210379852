import { apiPathWithApiHost } from "utils";

export const apiPaths = {
  comments: {
    getComments: {
      models: (activeWorkspace, modelId, pageNumber, itemsPerPage) =>
        `/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${modelId}/comments?page_number=${pageNumber}&items_per_page=${itemsPerPage}`,
      deployments: (activeWorkspace, deploymentId, pageNumber, itemsPerPage) =>
        `/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/comments?page_number=${pageNumber}&items_per_page=${itemsPerPage}`,
      collections: (activeWorkspace, collectionId, pageNumber, itemsPerPage) =>
        `/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/collections/${collectionId}/comments?page_number=${pageNumber}&items_per_page=${itemsPerPage}`,
      comments: (resourceId, pageNumber, itemsPerPage) =>
        `/comments/${resourceId}?page_number=${pageNumber}&items_per_page=${itemsPerPage}`,
    },

    getUsers: (activeWorkspace) =>
      `/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/users`,

    getFilteredUsers: (activeWorkspace, input) =>
      `/organisations/${activeWorkspace?.organisation_id}/search-user?search_string=${input}`,

    addComment: () =>
      apiPathWithApiHost(
        `/v1/comments/create` //Updated endpoint
      ),

    deleteComment: (comment_id) =>
      apiPathWithApiHost(
        `/v1/comments/delete/${comment_id}` //Updated Endpoint
      ),

    editComment: () =>
      apiPathWithApiHost(
        `/v1/comments/edit` //Updated endpoint
      ),

    addLike: (comment_id) => apiPathWithApiHost(`/v1/comments/like/${comment_id}`),

    removeLike: (comment_id) => apiPathWithApiHost(`/v1/comments/unlike/${comment_id}`),
  },
  cards: {
    toggleCard: (endpointType, id, action) =>
      apiPathWithApiHost(`/v1/${endpointType}/${action}/${id}`), //Updated endpoint
  },
  xPanel: {
    getXPanel: () => apiPathWithApiHost(`/v1/xpanel`),
  },
  user: {
    getUser: () => apiPathWithApiHost(`/v1/user`),
    createUser: () => apiPathWithApiHost(`/v1/create-user`),
    deleteUser: () => apiPathWithApiHost(`/v1/user`),
    userNotification: () => apiPathWithApiHost(`/v1/user/notification-preferences`),
  },
  users: {
    getUsers: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/users/${activeWorkspace?.organisation_id}`), //Updated endpoint
    getTeamUsers: (organisationId) =>
      apiPathWithApiHost(`/v1/organisations/users/${organisationId}`),
    searchUser: (organisationId, input) =>
      apiPathWithApiHost(`/v1/organisations/${organisationId}/search-user?search_string=${input}`),
  },
  models: {
    getModels: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/models/teams/${activeWorkspace?.team_id}` //Updated endpoint
      ),
    getModel: (model_id) => apiPathWithApiHost(`/v1/models/${model_id}`), //Updated endpoint
    deleteModel: (model_id) => apiPathWithApiHost(`/v1/models/${model_id}`), //Updated endpoint
    archiveModel: (model_id) => apiPathWithApiHost(`/v1/models/archive/${model_id}`), //Updated endpoint),
    moveModel: () => apiPathWithApiHost(`/v1/models/move-model`), //Updated endpoint
    deleteModelVersion: (selectedVersion) => apiPathWithApiHost(`/v1/models/versions/${selectedVersion.value}`), //Updated endpoint,
    handleActiveVersion: () => apiPathWithApiHost(`/v1/models/set-active-version`), //Updated endpoint
  },
  deployments: {
    getDeployments: (activeWorkspace) => apiPathWithApiHost(`/v1/deployments/teams/${activeWorkspace?.team_id}`), //Updated Endpoint),
    deleteDeployments: (deployment_id) => apiPathWithApiHost(`/v1/deployments/${deployment_id}`), // Updated endpoint
    handleDeployDeployment: (version_id) =>
      apiPathWithApiHost(
        `/v1/models/deploy/${version_id}` // Updated endpoint
      ),
    handleArchiveDeployment: (model_id) =>
      apiPathWithApiHost(
        `/v1/models/archive/${model_id}` // Updated endpoint
      ),
    getKey: (deployment_id) => apiPathWithApiHost(`/v1/deployments/${deployment_id}`), // Updated endpoint
    getPayload: (deployment_id) =>
      apiPathWithApiHost(`/v1/deployments/${deployment_id}/payload`),
    getPrediction: () => `https://inference.xplainable.io/v1/predict`, // Updated endpoint
  },
  toggle: {
    toggleDeployment: (deployment_id, action) =>
      apiPathWithApiHost(`/v1/deployments/${deployment_id}/${action}`), // Updated endpoint
    toggleModel: (model_id, action) =>
      apiPathWithApiHost(`/v1/models/${action}/${model_id}`), // Updated endpoint
  },
  invitations: {
    inviteUser: () => apiPathWithApiHost("/v1/invite"),
    getInvitations: () => apiPathWithApiHost(`/v1/teams/invitations/get`), //updated endpoint
    getRequests: () => apiPathWithApiHost(`/v1/requests`), //updated endpoint
    deleteInvitation: (invitationId) => apiPathWithApiHost(`/v1/invitations/${invitationId}`),
    handleInvitations: (type, requestId, action) =>
      apiPathWithApiHost(`/v1/teams/${type}/${action}/${requestId}`),
  },
  notifications: {
    getNotifications: () => apiPathWithApiHost(`/v1/notifications`),
    markAsRead: (notificationId) => apiPathWithApiHost(`/v1/notifications/${notificationId}`),
    markAllAsRead: () => apiPathWithApiHost(`/v1/notifications`),
  },
  preprocessors: {
    getAllPreprocessors: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/preprocessors/teams/${activeWorkspace?.team_id}`), //Updated endpoint
    getPreprocessorVersion: (activeWorkspace, preprocessor_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/preprocessors/${preprocessor_id}/versions`
      ),
    getPreprocessors: (version_id) =>
      apiPathWithApiHost(`/v1/preprocessors/link-preprocessor/${version_id}`), //Updated endpoint
    getRelevantPreprocessors: (model_version_id) =>
      apiPathWithApiHost(`/v1/preprocessors/models/${model_version_id}`), //New endpoint
    getPreprocessorSample: (activeWorkspace, model_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}/sample`
      ),
    checkSignature: (activeWorkspace, preprocessor_id, preprocessor_version) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/preprocessors/${preprocessor_id}/versions/${preprocessor_version}/check-signature`
      ),
    linkPreprocessor: () =>
      apiPathWithApiHost(`/v1/preprocessors/link-preprocessor `), //Updated endpoint,
    unlinkPreprocessor: () =>
      apiPathWithApiHost(`/v1/preprocessors/unlink-preprocessor `), //Updated endpoint,
  },
  profileData: {
    getProfileData: (version) =>
      apiPathWithApiHost(`/v1/models/profile/${version}`), //Updated endpoint
  },
  scenario: {
    addScenario: () =>
      apiPathWithApiHost(`/v1/collections/scenarios/add`), //Updated endpoint

    getScenario: (collection_id) =>
      apiPathWithApiHost(
        `/v1/collections/${collection_id}` //Updated endpoint
      ),
    deleteScenario: (activeWorkspace, modelId, collectionId, scenarioId) =>
      apiPathWithApiHost(`/v1/collections/scenarios/delete/${scenario_id}`), //Updated endpoint
  },
  collections: {
    getAllCollections: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/collections/teams/${activeWorkspace?.team_id}` //Updated endpoint
      ),
    getCollections: (model_id) =>
      apiPathWithApiHost(
        `/v1/collections/model/${model_id}` //Updated endpoint
      ),
    getCollection: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(`/v1/collections/${collection_id}`), //Updated endpoint
    createCollection: (activeWorkspace, modelId) => apiPathWithApiHost(`/v1/collections/create`), //Updated endpoint
    deleteCollection: (collection_id) =>
      apiPathWithApiHost(
        `/v1/collections/delete/${collection_id}` //Updated endpoint
      ),
    updateTitle: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(`/v1/models/name/${model_id}`), //Updated endpoint
    updateDescription: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(`/v1/models/description/${model_id}`), //Updated endpoint
    deleteModelCollections: (activeWorkspace, model_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/delete-collections`
      ),
    updateScenarioNotes: (activeWorkspace, model_id, collection_id, scenario_id) =>
      apiPathWithApiHost(`/v1/collections/scenarios/edit-notes`), //Updated endpoint
  },
  reports: {
    getReports: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/reports/teams/${activeWorkspace?.team_id}` //Updated Endpoint - waiting on confirmation
      ),
    getReportsVersion: (reportId) =>
      apiPathWithApiHost(
        `/v1/reports/versions/${reportId}` //Updated Endpoint - waiting on confirmation
      ),
    createReport: () => apiPathWithApiHost(`/v1/reports/create`),
    createReportVersion: (reportId) =>apiPathWithApiHost(`/v1/reports/create-version/${reportId}`), //Updated Endpoint - waiting on confirmation
    deleteReport: (reportId) => apiPathWithApiHost(`/v1/reports/${reportId}`),
    copyReport: (reportId) => apiPathWithApiHost(`/v1/reports/copy/${reportId}`),
  },
  health: {
    getHealthData: (selectedVersion) =>
      apiPathWithApiHost(`/v1/models/feature-info/${selectedVersion.value} `), //Updated endpoint
  },
  predict: {
    postPredict: () => apiPathWithApiHost("/v1/predict"),
  },
  subscription: {
    getSubscription: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/subscription/${activeWorkspace?.organisation_id}`), //Updated endpoint
    getSubscriptionSeats: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/seats/${activeWorkspace?.organisation_id}`),
    updateSubscriptionSeats: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/subscriptions/addon/${activeWorkspace?.organisation_id}`),
    createSubscription: () => apiPathWithApiHost(`/v1/organisations/subscriptions/create`),
    cancelSubscription: () => apiPathWithApiHost(`/v1/organisations/subscriptions/cancel`),
    resumeSubscription: () => apiPathWithApiHost(`/v1/organisations/subscriptions/resume`),
  },
  commentary: {
    getCommentary: (version_id) =>
      apiPathWithApiHost(`/v1/commentary/${version_id}/?page=Profile`), //Updated endpoint

    autoGenerateReport: (activeWorkspace, id, version) =>
      apiPathWithApiHost(`/v1/models/report/generate`),

    changeCommentary: (activeWorkspace, id, version) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${id}/versions/${version.value}/commentary/Profile`
      ),

    handlePublishCommentary: (action) =>
      apiPathWithApiHost(`/v1/commentary/${action}`),
  },
  binaryPerformance: {
    getData: (partition_id) =>
      apiPathWithApiHost(`/v1/models/evaluation/${partition_id}`),
  },
  pipeLine: {
    getData: (preprocessor_version) =>
      apiPathWithApiHost(
        `/v1/preprocessors/versions/${preprocessor_version}/pipeline`
      ),
  },
  regressionPerformance: {
    getData: (partition_id) =>
      apiPathWithApiHost(`/v1/models/evaluation/${partition_id}`), //Updated endpoint
  },
  organisation: {
    getData: () => apiPathWithApiHost(`/v1/organisations`),
    createOrganisation: () => apiPathWithApiHost(`/v1/create-organisation`),
    deleteOrganisation: (organisation_id) =>
      apiPathWithApiHost(`/v1/organisations/${organisation_id}`),
    getOrganisationMetadata: () => apiPathWithApiHost(`/v1/organisations/metadata`),
    manageOrganisation: () => apiPathWithApiHost(`/v1/subscriptions/customer-portal`),
  },
  teams: {
    getTeams: (organisationId) => apiPathWithApiHost(`/v1/organisations/teams/${organisationId}`), //Updated endpoint
    createTeam: (organisationData) =>
      apiPathWithApiHost(`/v1/organisations/${organisationData?.organisation_id}/create`), //Updated Endpoint
    deleteTeam: (organisationData, team_id) =>
      apiPathWithApiHost(`/v1/organisations/${organisationData?.organisation_id}/teams/${team_id}`),
  },
  apiKeys: {
    getData: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/teams/${activeWorkspace?.team_id}/keys` //Updated the endpoint
      ),

    createKey: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/teams/${activeWorkspace?.team_id}/create-bound-api-key`
      ),

    revokeAllKeys: () => apiPathWithApiHost("/v1/revoke-all-user-team-keys"),
  },
  batchOptimisation: {
    getData: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers`
      ),
    getOptimisersVersions: (activeWorkspace, optimiser_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions`
      ),
    getOptimiserVersion: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}`
      ),
    createOptimiserVersion: (activeWorkspace, optimiser_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/create-version`
      ),
    deleteOptimiser: (activeWorkspace, optimiser_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}`
      ),
    createOptimiser: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/create-optimiser`
      ),
    deleteOptimiserVersion: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}`
      ),
    updateOptimiserVersion: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}`
      ),
    resetOptimiserVersion: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/reset`
      ),
    createBatches: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/create-batches`
      ),
    getBatch: (activeWorkspace, optimiser_id, version_id, batch_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/batches/${batch_id}/frontend`
      ),
    downloadBatch: (activeWorkspace, optimiser_id, version_id, batch_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/batches/${batch_id}/download`
      ),
    getRunBatches: (activeWorkspace, optimiser_id, version_id, run_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/runs/${run_id}/batches`
      ),
  },
  dataset: {
    getData: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/datasets/teams/${activeWorkspace?.team_id}` //TODO: Updated but add the s once this is added
      ),
    createDataset: () =>
      apiPathWithApiHost(
        `/v1/datasets/upload` //Updated Endpoint
      ),
    getPreview: (dataset_id) => apiPathWithApiHost(`/v1/datasets/${dataset_id}/preview`), //Updated endpoint
    deleteDataset: (dataset_id) =>
      apiPathWithApiHost(
        `/v1/datasets/${dataset_id}` //Updated endpoint
      ),
    updateDataSet: (dataset_id) => apiPathWithApiHost(`/v1/datasets/${dataset_id}`), //Updated endpoint
    getItemData: ( dataset_id) =>
      apiPathWithApiHost(
        `/v1/datasets/${dataset_id}` //Updated endpoint
      ),
    downloadDataset: (dataset_id) =>
      apiPathWithApiHost(
        `/v1/datasets/${dataset_id}/download` //Updated Endpoint
      ),
  },
  runs: {
    createRun: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/create-run`
      ),
    getData: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/runs`
      ),
    getRunData: (activeWorkspace, optimiser_id, version_id, run_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/runs/${run_id}/frontend`
      ),
    getBatchData: (activeWorkspace, optimiser_id, version_id, batch_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/batches/${batch_id}/frontend`
      ),
  },
  output: {
    enableExplain: (deployment_id) =>
      apiPathWithApiHost(`/v1/deployments/${deployment_id}/enable-explain`), //Updated endpoint

    disableExplain: (deployment_id) =>
      apiPathWithApiHost(`/v1/deployments/${deployment_id}/disable-explain`), //Updated endpoint
  },
  partitions: {
    getPartitions: (version_id) =>
      apiPathWithApiHost(
        `/v1/models/partitions/${version_id}` //Updated endpoint
      ),
  },
  preferences: {
    updatePreferences: () => apiPathWithApiHost(`/v1/user/preferences`),
  },
  request: {
    sendRequest: () => "https://formbold.com/s/3Gq10",
  },
  deployKeys: {
    getDeployKeys: (deploymentId) =>
      apiPathWithApiHost(
        `/v1/deployments/${deploymentId}` //Updated Endpoint
      ),
    createDeployKey: () =>
      apiPathWithApiHost(
        `/v1/deployments/create-deploy-key` //Updated Endpoint
      ),
    deleteDeployKey: (deploymentId) =>
      apiPathWithApiHost(`/v1/deployments/${deploymentId}/revoke-all-user-deploy-keys`), //Updated Endpoint
    revokeAllDeployKeys: (deploymentId) =>
      apiPathWithApiHost(
        `/v1/deployments/${deploymentId}/revoke-all-user-deploy-keys` //Updated Endpoint
      ),
    getActiveDeployKeys: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/deployments/teams/${activeWorkspace?.team_id}/active-keys` //Updated Endpoint
      ),
  },
  network: {
    createIpAddress: (deploymentId) =>
      apiPathWithApiHost(
        `/v1/organisations/deployments/${deploymentId}/add-allowed-ip` //Updated Endpoint
      ),
    handleToggleChange: (deployment_id, action) =>
      apiPathWithApiHost(
        `/v1/deployments/${deployment_id}/${action}` //Updated Endpoint
      ),
    getFirewalls: (deploymentId) =>
      apiPathWithApiHost(
        `/v1/deployments/${deploymentId}/get-firewall-description` //Updated Endpoint
      ),
    removeIpAddress: (deploymentId, sourceToRemove) =>
      apiPathWithApiHost(
        `/v1/deployments/${deploymentId}/ips/${sourceToRemove.ip_id}` //Updated Endpoint
      ),
  },
  calendar: {
    getCalendarData: () => apiPathWithApiHost("/v1/login-events"), //Updated Endpoint
  },
  recent: {
    getRecentData: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/recent/${activeWorkspace?.team_id}`), //Updated endpoint
  },
  accountSettings: {
    updateProfileImage: () => apiPathWithApiHost(`/v1/user/profile-image`),
    updateDetails: () => apiPathWithApiHost(`/v1/user/update-details`),
  },
  configuration: {
    saveConfiguration: () =>
      apiPathWithApiHost(`/v1/models/update-feature-info `),
  },
  login: {
    login: () => apiPathWithApiHost(`/v1/login`),
    limits: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/limits/${activeWorkspace?.organisation_id}`), //Updated Endpoint
  },
  train: {
    dataPrep: () => apiPathWithApiHost(`/v1/autotrain/dataprep/recommend`), //Updated Endpoint
    applyStep: () => apiPathWithApiHost(`/v1/autotrain/dataprep/apply`), //Updated Endpoint
    downloadDataprep: () => apiPathWithApiHost(`/v1/autotrain/dataprep/download`), //Updated Endpoint
    dropDataprep: () =>apiPathWithApiHost(`/v1/autotrain/dataprep/drop`),
    summarize: () => apiPathWithApiHost(`/v1/autotrain/summarize`), //Updated Endpoint
    summarizeUrl: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/summarize/url`
      ),
    goal: () => apiPathWithApiHost(`/v1/autotrain/goal`), //Updated Endpoint
    featureEngineering: () => apiPathWithApiHost(`/v1/autotrain/feature_engineering`), //Updated Endpoint
    insights: () => apiPathWithApiHost(`/v1/autotrain/insights`), //Updated Endpoint
    visualize: () => apiPathWithApiHost(`/v1/autotrain/visualize`), //Updated Endpoint
    visualizeEdit: () => apiPathWithApiHost(`/v1/autotrain/visualize/edit`), //Updated Endpoint
    visualizeRepair: () => apiPathWithApiHost(`/v1/autotrain/visualize/repair`), //Updated Endpoint
    visualizeExplain: () => apiPathWithApiHost(`/v1/autotrain/visualize/explain`), //Updated Endpoint
    visualizeEvaluate: () => apiPathWithApiHost(`/v1/autotrain/visualize/evaluate`), //Updated Endpoint
    visualizeRecommend: () => apiPathWithApiHost(`/v1/autotrain/visualize/recommend`), //Updated Endpoint
    textGenerate: () => apiPathWithApiHost(`/v1/autotrain/text/generate`), //Updated Endpoint
    infographer: () => apiPathWithApiHost(`/v1/autotrain/text/infographer`),
    getModels: () => apiPathWithApiHost(`/v1/llmmodels`),
    autoTrain: () => apiPathWithApiHost(`/v1/autotrain/auto_train`), //Updated Endpoint
    train: () => apiPathWithApiHost(`/v1/autotrain/train`), //Updated Endpoint
    getApiKey: (modelProvider) => apiPathWithApiHost(`/v1/user/autotrain_keys/${modelProvider}`),
    setApiKey: () => apiPathWithApiHost(`/v1/user/autotrain_keys`),
  },
};
