import React, { useState, useEffect } from "react";
import { Grid, AppBar, Tabs, Tab, Card } from "@mui/material";

import PropTypes from "prop-types";

//Xplainable Components
import XTypography from "components/XTypography";
import XBox from "components/XBox";

//User defined components
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import breakpoints from "assets/theme/base/breakpoints";
import PerformanceChart from "./Components/PerformanceChart";
import colors from "assets/theme/base/colors";
import { MdMultilineChart } from "react-icons/md";
import { BiLineChartDown } from "react-icons/bi";
import { useXplainableController } from "context";
import DefaultItem from "shared/Items/DefaultItem";

const metricDescriptions = {
  accuracy: "Proportion of correct predictions (both true positives and true negatives)",
  precision: "Proportion of positive predictions that were correctly identified",
  recall: "Proportion of actual positives that were correctly identified",
  f1: "Harmonic mean of precision and recall, balancing both metrics",
  "auc pr": "Area under the Precision-Recall curve - model's performance across different thresholds",
  mcc: "Matthews Correlation Coefficient - balanced measure of quality for binary classification",
  "log loss": "Logarithmic loss - measures accuracy of probabilistic predictions",
  "roc auc": "Area under the ROC curve - model's ability to distinguish between classes",
};

function Metrics({ metricsData, precisionRecallData, rocData, isLoading }) {
  const [controller, dispatch] = useXplainableController();
  const { darkMode } = controller;

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  // Calculate number of rows needed based on the number of ImageCard components
  const numRows = Math.ceil(metricsData.length / 2);

  // Create an array of arrays to group ImageCard components into rows
  const rows = Array.from({ length: numRows }, (_, i) => metricsData.slice(i * 2, i * 2 + 2));

  return (
    <Grid container>
      <Card sx={{ p: 2, width: "100%" }}>
        <Grid item xs={12}>
          <XTypography variant="h5" py={1} fontSize="18px">
            Metrics
          </XTypography>
          <XTypography variant="h6" color="secondary" fontWeight="light">
            Key performance metrics for model evaluation
          </XTypography>
        </Grid>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={12} lg={6}>
            {isLoading ? (
              <XBox height={"300px"}>
                <LoadingSpinner size={50} />
              </XBox>
            ) : (
              <Card sx={{ boxShadow: "none" }}>
                <AppBar position="static">
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                    sx={{
                      backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,

                      transition: "all 500ms ease",
                      color: "#AFAFAF",

                      "& .Mui-selected": {
                        fontWeight: "600",
                        color: `${darkMode ? "white" : "black"} !important`,
                        backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
                      },
                      "& .MuiTabs-indicator": {
                        display: "none",
                      },
                    }}
                  >
                    <Tab
                      label="ROC Curve"
                      icon={<MdMultilineChart />}
                      sx={{
                        minHeight: "36px",
                      }}
                    />
                    <Tab
                      label="PR Curve"
                      icon={<BiLineChartDown />}
                      sx={{
                        minHeight: "36px",
                      }}
                    />
                  </Tabs>
                </AppBar>
                <PerformanceChart
                  data={tabValue === 0 ? rocData : precisionRecallData}
                  type={tabValue === 0 ? "ROC" : "PR"}
                />
              </Card>
            )}
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            {isLoading ? (
              <XBox height={"300px"}>
                <LoadingSpinner size={50} />
              </XBox>
            ) : (
              <XBox >
                <Grid container spacing={2}>
                  {metricsData.map((metric, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <DefaultItem
                        color="xpblue"
                        bgColor={colors.light.main}
                        title={metric.title}
                        description={Number(metric.value)}
                        tooltipText={metricDescriptions[metric.title.toLowerCase()]}
                        p={1}
                        borderRadius="lg"
                      />
                    </Grid>
                  ))}
                </Grid>
              </XBox>
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default Metrics;

Metrics.propTypes = {
  metricsData: PropTypes.array,
  rocData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  precisionRecallData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isLoading: PropTypes.bool,
};
