import { useDropzone } from "react-dropzone";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useTrainMutation } from "api/mutations";
import { useAutoTrain, useToast } from "hooks";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Papa from "papaparse";

export const useAutoTrainContent = (modelSettings) => {
  const {
    goalData,
    activeStep,
    summarizeData,
    tabValue,
    setTabValue,
    fileMetadata,
    setSummarizeData,
    setGoalData,
    setFileMetadata,
    setRecommendations,
    isDataLoading,
    setIsDataLoading,
    setFeatureEngineeringData,
    setActiveGoal,
    uploadSuccessful,
    setUploadSuccessful,
    isLoading,
    setIsLoading,
    setActiveStep,
    setColumnKeys
  } = useAutoTrain();

  const { activeWorkspace } = useApiKey();
  const { summarizeMutation, goalMutation, dataPrepMutation, featureEngineeringMutation } =
    useTrainMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const [fileNames, setFileNames] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isGoalLoading, setIsGoalLoading] = useState(false);
  const [datasetName, setDataSetName] = useState("");

  const generateFiles = async (acceptedFiles) => {
    let files = acceptedFiles;

    if (typeof acceptedFiles[0] === "string") {
      const fileName = datasetName;
      const fileContent = acceptedFiles[0];
      const fileType = "text/csv";

      const datasetBlob = new Blob([fileContent], { type: fileType });

      const datasetFile = new File([datasetBlob], fileName, {
        type: fileType,
        lastModified: new Date(),
      });

      files = [datasetFile];
    }

    return files;
  };

  const textGenConfig = {
    provider: modelSettings.provider,
    model: modelSettings?.model,
    n: modelSettings?.n,
    temperature: modelSettings?.temperature,
    max_tokens: modelSettings?.max_tokens,
    top_p: modelSettings?.top_t,
    stream: modelSettings?.stream,
    stop: modelSettings?.stop,
    presence_penalty: modelSettings?.presence_penalty,
    frequency_penalty: modelSettings?.frequency_penalty,
  };

  const calculateSummaryMetrics = (data, topX = 2) => {
    const summaryMetrics = {};
  
    // Extract the headers (first row) to use as keys
    const headers = data[0];
  
    // Remove the first row (header) from data
    const dataWithoutHeader = data.slice(1);
  
    for (const column of headers) {
      const columnIndex = headers.indexOf(column);
      const columnData = dataWithoutHeader.map((row) => row[columnIndex]);
  
      if (columnData.every(value => !isNaN(value) && typeof parseFloat(value) === 'number')) {
        const numericData = columnData.map(value => parseFloat(value));
        const filteredData = numericData.filter(value => !isNaN(value));
        const nanCount = numericData.length - filteredData.length;
  
        const mean = filteredData.reduce((a, b) => a + b, 0) / filteredData.length;
        const median = filteredData.sort((a, b) => a - b)[Math.floor(filteredData.length / 2)];
        const std = Math.sqrt(filteredData.reduce((sq, n) => sq + Math.pow(n - mean, 2), 0) / filteredData.length);
        const min = Math.min(...filteredData);
        const max = Math.max(...filteredData);
  
        // Aggregate data into bins for histogram
        const binCount = 10; // Number of bins
        const binWidth = (max - min) / binCount;
        const bins = Array(binCount).fill(0);
  
        filteredData.forEach(value => {
          const binIndex = Math.min(Math.floor((value - min) / binWidth), binCount - 1);
          bins[binIndex]++;
        });
  
        summaryMetrics[column] = {
          type: "numeric",
          values: { mean, median, std, min, max, bins, binWidth, binCount, nanCount }
        };
      } else {
        const valueCounts = columnData.reduce((acc, value) => {
          acc[value] = (acc[value] || 0) + 1;
          return acc;
        }, {});
  
        // Convert valueCounts to an array of objects for sorting
        const combined = Object.keys(valueCounts).map(label => ({
          label,
          value: valueCounts[label]
        }));
  
        // Sort by value in descending order
        combined.sort((a, b) => b.value - a.value);
  
        // Get top X and group the rest into "OTHER"
        const top = combined.slice(0, topX);
        const others = combined.slice(topX);
  
        const otherTotalValue = others.reduce((acc, curr) => acc + curr.value, 0);
  
        if (otherTotalValue > 0) {
          top.push({ label: 'OTHER', value: otherTotalValue });
        }
  
        // Convert back to an object
        const groupedValueCounts = top.reduce((acc, { label, value }) => {
          acc[label] = value;
          return acc;
        }, {});
  
        summaryMetrics[column] = {
          type: "category",
          values: groupedValueCounts
        };
      }
    }
  
    return summaryMetrics;
  };
  
  const onDropAccepted = async (acceptedFiles) => {
    setIsLoading(true);
    setUploadProgress(0);
    setGoalData([]);
    setSummarizeData(null);
    setFileNames(acceptedFiles.map((file) => file.name));
    setActiveStep(1);

    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        complete: function (results) {
          const summaryMetrics = calculateSummaryMetrics(results.data);
          const headerColumns = results.data[0];

          const metadata = {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            rowCount: results.data.length,
            headerColumns: results.data[0],
            data: results.data.slice(0, 11),
            summaryMetrics: summaryMetrics,
          };
          setFileMetadata([metadata]);
          setColumnKeys(headerColumns);
          setUploadSuccessful(true);
          setUploadProgress(33);
        },

        error: function (err) {
          console.error(`Papa Parse error: ${err}`);
        },
      });
    });

    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    try {
      const files = await generateFiles(acceptedFiles);

      setFileNames(files.map((file) => file.name));

      const parsePromises = files.map(
        (file) =>
          new Promise((resolve, reject) => {
            Papa.parse(file, {
              complete: function (results) {
                const summaryMetrics = calculateSummaryMetrics(results.data);
                const headerColumns = results.data[0];

                const metadata = {
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  lastModified: file.lastModified,
                  rowCount: results.data.length,
                  headerColumns: results.data[0],
                  data: results.data.slice(0, 11),
                  summaryMetrics: summaryMetrics,
                };
          
                setFileMetadata([metadata]);
                setColumnKeys(headerColumns);
                setUploadSuccessful(true);
                setUploadProgress(33);
                resolve();
              },

              error: function (err) {
                console.error(`Papa Parse error: ${err}`);
                reject(err);
              },
            });
          })
      );

      await Promise.all(parsePromises);

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("textgen_config", JSON.stringify(textGenConfig));
      formData.append("team_id", activeWorkspace?.team_id)

      const { data: summarizeData } = await summarizeMutation([formData]);
      if (!summarizeData.status) {
        showErrorToast(summarizeData);
        resetUpload();
        return;
      }

      setUploadProgress(66);
      setSummarizeData(summarizeData);
      summarizeData["n"] = 10;
      summarizeData["textgen_config"] = textGenConfig;
      const stringifyData = JSON.stringify(
        {req: summarizeData, team_id: activeWorkspace?.team_id}
      );

      const { data: recommendationData } = await dataPrepMutation([stringifyData]);
      if (!recommendationData.status) {
        showErrorToast(recommendationData?.message);
        resetUpload();
        return;
      }
      setRecommendations(recommendationData.data);
      showSuccessToast(recommendationData?.message);
      
      setUploadProgress(100);
      setIsLoading(false);
      setUploadProgress(0);
      setActiveStep(2);
    } catch (error) {
      console.log(error, "error");
      if (error?.response?.status === 422) {
        setTabValue(1)
        showErrorToast("The model didn't have the context required to summarise the data. Consider using a larger model with higher max token length in the Advanced Settings tab.", 10000);
      } else {
        showErrorToast(error?.response?.data?.message || "Error", 5000);
      }
      resetUpload();
      setIsLoading(false);
    }
  };

  const resetUpload = () => {
    setFileNames("");
    setUploadProgress(0);
    setFileMetadata([]);
    setUploadSuccessful(false);
    setIsLoading(false);
    setActiveStep(0); // Reset the step
  };

  const { getRootProps, getInputProps } = useDropzone({
    addRemoveLinks: true,
    maxFiles: 1,
    onDrop: onDropAccepted,
    accept: {
      "text/csv": [".csv"],
      "text/json": [".json"],
    },
  });

  useEffect(() => {
    if (!activeStep || !summarizeData) return;

    const data = { ...summarizeData, textgen_config: textGenConfig };
    data["n"] = 10;
    const stringifyData = JSON.stringify(
      {
        team_id:activeWorkspace.team_id,
        req:data}
    );

    const fetchData = async () => {
      try {
        if (activeStep === 1) {
          setIsDataLoading(true);

          const { data: featureEngineeringData } = await featureEngineeringMutation([
            stringifyData,
          ]);

          setFeatureEngineeringData(featureEngineeringData);
          setIsDataLoading(false);
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.message || "Error", 5000);
        setIsDataLoading(false);
      }
    };

    fetchData();
  }, [activeStep, summarizeData]);

  const fetchGoals = async () => {
    if (goalData.length > 0) return;

    setIsGoalLoading(true);

    const stringifyData = JSON.stringify(
      {
      team_id: activeWorkspace.team_id,
      req: { ...summarizeData, textgen_config: textGenConfig }
    }
    );

    try {
      const { data } = await goalMutation([stringifyData]);
      setGoalData(data.data);
      setIsGoalLoading(false);
      showSuccessToast(data?.message);
    } catch (error) {
      showErrorToast("Error");
      setIsGoalLoading(false);
    }
  };

  useEffect(() => {
    setActiveGoal("");
  }, [tabValue]);

  return {
    tabValue,
    uploadSuccessful,
    isLoading,
    uploadProgress,
    fileNames,
    setGoalData,
    fetchGoals,
    summarizeData,
    isGoalLoading,
    isDataLoading,
    fileMetadata,
    activeStep,
    getRootProps,
    getInputProps,
    onDropAccepted,
    setDataSetName,
    setUploadProgress,
    resetUpload,
  };
};
