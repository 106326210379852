import React, { useState, useRef } from "react";
import { useModel } from "hooks";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Card, Grid, Divider, Icon, Tooltip } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import colors from "assets/theme/base/colors";

import DefaultItem from "shared/Items/DefaultItem";
import { IoMdCopy } from "react-icons/io";
import EventIcon from "shared/Icons/EventIcons";
import { useXplainableController } from "context";

const Parameters = () => {
  const { viewState } = useApiKey();
  const { paramData, trainingMetadata } = useModel();

  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const paramDataRef = useRef();
  const [copied, setCopied] = useState(false);
  console.log("The traning metadata is", trainingMetadata);

  const handleCopyClick = () => {
    const textarea = document.createElement("textarea");
    textarea.textContent = JSON.stringify(paramData, null, 2);
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  if (trainingMetadata && "fit_time" in trainingMetadata) {
    trainingMetadata.fit_time = roundTo3SF(trainingMetadata.fit_time);
  }
  // Extract the optimiser object and then remove it from the original metadata
  const optimiserData = trainingMetadata.optimiser;
  const metadataWithoutOptimiser = { ...trainingMetadata };
  delete metadataWithoutOptimiser.optimiser;

  // Transform the modified metadata into a rows-like structure
  const metadataToRows = () => {
    const entries = Object.entries(metadataWithoutOptimiser);
    let rows = [];
    for (let i = 0; i < entries.length; i += 2) {
      let firstItem = { title: entries[i][0], value: entries[i][1] };
      let secondItem =
        i + 1 < entries.length ? { title: entries[i + 1][0], value: entries[i + 1][1] } : null;
      rows.push([firstItem, secondItem]);
    }
    return rows;
  };

  const rows = metadataToRows();

  const renderOptimiserTooltip = () => {
    return (
      <div>
        {Object.entries(optimiserData).map(([key, value], index) => (
          <div key={index}>
            <strong>{key}:</strong> {value.toString()}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Grid container spacing={1}>
      <Card sx={{ p: 2, width: "100%" }}>
        {viewState === "creator" && (
          <>
            <Grid item xs={12}>
              <XBox display="flex" justifyContent="space-between" alignItems="center">
                <div>
                  <XTypography variant="h5" py={1} fontSize="18px">
                    Model Parameters
                  </XTypography>
                  <XTypography variant="h6" color="secondary" fontWeight="light">
                    What parameters were used to create the model profile?
                  </XTypography>
                </div>
              </XBox>
            </Grid>
            <XBox display="flex">
              <Grid item xs={12} lg={6} mt={1}>
                <Card variant="outlined" sx={{ boxShadow: "none" }}>
                  <XBox p={2} display="flex" flexDirection="column" position="relative">
                    <XTypography variant="h6" fontWeight="bold" mb={1}>
                      Parameters
                    </XTypography>
                    <XBox
                      display="flex"
                      position="absolute"
                      top={10}
                      right={0}
                      alignItems="center"
                      zIndex={1}
                      p={1}
                    >
                      <EventIcon
                        onClick={handleCopyClick}
                        tooltipText="Copy Parameters to Clipboard"
                        icon={<IoMdCopy />}
                        copied={copied}
                      />
                    </XBox>
                    <XBox
                      display="flex"
                      p={2}
                      flexDirection="column"
                      bgColor={darkMode ? "red" : "green"}
                      sx={{
                        borderRadius: "16px",
                        background: darkMode ? "#1D1B1B" : "#F7F7F8",
                      }}
                    >
                      <XTypography
                        bgColor={colors.light.main}
                        variant="body1"
                        fontSize="16px"
                        component="pre"
                        ref={paramDataRef}
                      >
                        {JSON.stringify(paramData, null, 2)}
                      </XTypography>
                    </XBox>
                  </XBox>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} mt={1}>
                <Card sx={{ px: 2, py: 4, boxShadow: "none" }}>
                  <Grid container spacing={2}>
                    {rows.map((row, i) => (
                      <React.Fragment key={i}>
                        <Grid item xs={6}>
                          <DefaultItem
                            color="xpblue"
                            bgColor={colors.light.main}
                            title={row[0].title}
                            description={row[0].value.toString()}
                            tooltipText={row[0].title === "optimised" && row[0].value ? renderOptimiserTooltip() : undefined}
                            p={1}
                            borderRadius="lg"
                          />
                        </Grid>
                        {row[1] && (
                          <Grid item xs={6}>
                            <DefaultItem
                              color="xpblue"
                              bgColor={colors.light.main}
                              title={row[1].title}
                              description={row[1].value.toString()}
                              p={1}
                              borderRadius="lg"
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </Card>
              </Grid>
            </XBox>
          </>
        )}
      </Card>
    </Grid>
  );
};

export default Parameters;

const roundTo3SF = (num) => {
  if (num === 0) return 0;

  const factor = Math.pow(10, 2 - Math.floor(Math.log10(Math.abs(num))));
  return Math.round(num * factor) / factor;
};
