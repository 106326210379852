import React, { useEffect, useState, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Grid } from "@mui/material";
import { useBinaryPerformanceQuery } from "api/query";
import { useModel } from "hooks";
import { ConfusionChart } from "./components/ConfusionChart";

import breakpoints from "assets/theme/base/breakpoints";
import XBox from "components/XBox";
import XSelect from "components/XSelect";
import XTypography from "components/XTypography";
import Optimisation from "../../../layouts/models/model/components/performance/components/Optimisation";
import Parameters from "../../../layouts/models/model/components/performance/components/shared/Parameters";
import Metrics from "../Metrics";
import ProbabilityChart from "./components/ProbabilityChart";
// import ScrollReveal from "scrollreveal"; // Removed for debugging
import LoadingSpinner from "shared/Animations/LoadingAnimation";

const BinaryPerformance = () => {
  const { selectedPartition } = useModel();
  const { logout } = useAuth0();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [selectedDataset, setSelectedDataset] = useState({ value: "train", label: "Train" });
  const [filteredData, setFilteredData] = useState(null);
  const [metricsData, setMetricsData] = useState([]);
  const [scoreData, setScoreData] = useState([]);
  const [precisionRecallData, setPrecisionRecallData] = useState([]);
  const [rocData, setRocData] = useState([]);
  const [confusionData, setConfusionData] = useState({});
  const [threshold, setThreshold] = useState(50);
  const [probabilityData, setProbabilityData] = useState([]);
  const [probabilityMap, setProbabilityMap] = useState({});

  // Use React Query to fetch performance data
  const { data, isLoading, error } = useBinaryPerformanceQuery(
    selectedPartition?.value,
    logout
  );

  console.log("Fetched Data:", data);

  // Handle window resize for tabs orientation
  useEffect(() => {
    const handleTabsOrientation = () => {
      if (window.innerWidth < breakpoints.values.sm) {
        setTabsOrientation("vertical");
      } else {
        setTabsOrientation("horizontal");
      }
    };

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation(); // Set initial orientation

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  // Filter data based on selected dataset (train or validation)
  useEffect(() => {
    if (data && data.data && data.data.evaluation) {
      const filtered =
        selectedDataset.value === "validation"
          ? data.data.evaluation.validation
          : data.data.evaluation.train;

      setFilteredData(filtered || null);

      // Extract score values if available
      if (filtered?.scores) {
        const scoreValues = filtered.scores.map(({ fp, fn, tp, tn }) => ({
          fp,
          fn,
          tp,
          tn,
        }));
        setScoreData(scoreValues);
      } else {
        setScoreData([]);
      }

      // Set probabilityData and probabilityMap if they exist in the data
      if (filtered?.probability_data) {
        setProbabilityData(filtered.probability_data);
      } else {
        setProbabilityData([]);
      }

      if (filtered?.probability_map) {
        setProbabilityMap(filtered.probability_map);
      } else {
        setProbabilityMap({});
      }
    }
  }, [selectedDataset, data]);

  // Process metrics and confusion data based on threshold
  useEffect(() => {
    if (
      filteredData &&
      filteredData.scores &&
      filteredData.auc_pr !== undefined &&
      filteredData.mcc !== undefined &&
      filteredData.log_loss !== undefined &&
      filteredData.roc_auc !== undefined &&
      filteredData.roc !== undefined &&
      filteredData.precision_recall_curve !== undefined
    ) {
      const roundedThreshold = Math.round(threshold);

      console.log(`Processing threshold: ${roundedThreshold}`);

      // Ensure the threshold index is within bounds
      if (roundedThreshold < 0 || roundedThreshold >= filteredData.scores.length) {
        console.warn(`Threshold ${roundedThreshold} is out of bounds.`);
        return;
      }

      const scores = filteredData.scores[roundedThreshold];

      if (scores) {
        const metrics = [
          { title: "F1", value: scores.f1.toFixed(3) },
          { title: "Accuracy", value: scores.accuracy.toFixed(3) },
          { title: "Precision", value: scores.precision.toFixed(3) },
          { title: "Recall", value: scores.recall.toFixed(3) },
          { title: "AUC PR", value: filteredData.auc_pr.toFixed(3) },
          { title: "MCC", value: filteredData.mcc.toFixed(3) },
          { title: "Log Loss", value: filteredData.log_loss.toFixed(3) },
          { title: "ROC AUC", value: filteredData.roc_auc.toFixed(3) },
        ];

        const filteredConfusionData = {
          fn: scores.fn,
          fp: scores.fp,
          tn: scores.tn,
          tp: scores.tp,
        };

        console.log("Metrics:", metrics);
        console.log("Confusion Data:", filteredConfusionData);

        setPrecisionRecallData(filteredData.precision_recall_curve);
        setRocData(filteredData.roc);
        setMetricsData(metrics);
        setConfusionData(filteredConfusionData);
      }
    }
  }, [threshold, filteredData]);

  // Handle dataset selection
  const handleDatasetChange = (dataset) => {
    setSelectedDataset(dataset);
  };

  // Initialize ScrollReveal animations (commented out for debugging)
  /*
  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);
  */

  return (
    <XBox py={2}>
      <Grid container>
        <Grid item xs={12}>
          {/* Header Section - Always Visible */}
          <Card
            sx={{
              p: 2,
              position: "relative",
              zIndex: 100,
              overflow: "visible",
              mb: 3,
            }}
          >
            <XBox display="flex" justifyContent="space-between" gap={3}>
              <XBox>
                <XTypography variant="h5" fontSize="24px">
                  Threshold Analysis
                </XTypography>
                <XTypography variant="h6" color="secondary" fontWeight="light">
                  Analyze how the threshold changes key performance metrics
                </XTypography>
              </XBox>
              <XBox maxWidth="280px">
                <XTypography variant="overline" fontSize="14px">
                  Selected Dataset
                </XTypography>
                <XSelect
                  placeholder="Filter on Dataset"
                  options={[
                    { value: "validation", label: "Validation" },
                    { value: "train", label: "Train" },
                  ]}
                  selected={selectedDataset}
                  onChange={handleDatasetChange}
                />
              </XBox>
            </XBox>
          </Card>

          {/* Conditional Content Section */}
          <Grid container spacing={4}>
            {/* Display Loading Spinner */}
            {isLoading && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    height: "400px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingSpinner size={50} animationType="pulse" />
                </Card>
              </Grid>
            )}

            {/* Display Error Message */}
            {error && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    height: "200px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  <XBox color="error.main">
                    {error.message || "Error loading performance data."}
                  </XBox>
                </Card>
              </Grid>
            )}

            {/* Display No Data Message */}
            {!isLoading && !error && !filteredData && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    height: "200px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  <XBox color="text.secondary">
                    No performance data available for the {selectedDataset.value} dataset.
                  </XBox>
                </Card>
              </Grid>
            )}

            {/* Display Main Content If Data is Available */}
            {filteredData && (
              <>
                <Grid item xs={12} lg={6} mt={1}>
                  <Card sx={{ p: 2, height: "460px" }} id="probability-chart">
                    <ProbabilityChart
                      id="probability--plot"
                      data={probabilityData}
                      dataset={selectedDataset.value}
                      fpm={probabilityMap}
                      margin={{
                        top: 20,
                        right: 40,
                        bottom: 20,
                        left: 40,
                      }}
                      setThreshold={setThreshold}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6} mt={1}>
                  <Card sx={{ p: 2, height: "460px", position: "relative" }}>
                    <ConfusionChart
                      id="confusion--plot"
                      confusionData={confusionData}
                      threshold={threshold}
                      margin={{
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      }}
                    />
                  </Card>
                </Grid>

                {/* Metrics, Optimisation, and Parameters */}
                <Grid item xs={12}>
                  <Metrics
                    metricsData={metricsData}
                    rocData={rocData}
                    precisionRecallData={precisionRecallData}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Optimisation scoreData={scoreData} />
                </Grid>
                <Grid item xs={12}>
                  <Parameters />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </XBox>
  );
};

export default BinaryPerformance;
