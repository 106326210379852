import { ReactComponent as SendIcon } from "assets/images/icons/send-icon.svg";
import { ReactComponent as ChevronDownIcon } from "assets/images/icons/train/chevron-down-icon.svg";
import { ReactComponent as ChevronUpIcon } from "assets/images/icons/train/chevron-up-icon.svg";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import { useToast } from "hooks";
import { useNavigate } from "react-router-dom";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import React, { useState } from "react";

import { Card, Collapse, Icon, Modal } from "@mui/material";
import { useTrainMutation } from "api/mutations";
import { queryClient } from "api/queryClient";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import { XImg } from "components/XImg";
import { QUERY_CONSTANTS } from "constants";
import { useAutoTrain } from "hooks";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import Skeleton from "@mui/material/Skeleton";

export const FooterBlock = ({}) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { activeWorkspace } = useApiKey();
  const {
    activeStep,
    setActiveStep,
    recommendations,
    isDataLoading,
    featureEngineeringData,
    isSuggestionVisible,
    setIsSuggestionVisible,
    checkedState,
    selectedPredictionLabel,
    partition,
    titlesArray,
    setPreprocessor,
    preprocessor,
    setIsApplyLoading,
    isApplyLoading,
    isPipeLineLoading,
    setSummaryMetrics,
  } = useAutoTrain();
  const { trainMutation, applyStepMutation, dropDataprepMutation } = useTrainMutation();
  const { showErrorToast, showSuccessToast } = useToast();
  const navigate = useNavigate();

  const [activeRecommendations, setActiveRecommendations] = useState([]);
  const [activeFeatureEngineering, setActiveFeatureEngineering] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelInfo, setModelInfo] = useState({ modelName: "", modelDescription: "" });
  const [applyInput, setApplyInput] = useState("");

  const toggleActiveFeature = (question) => {
    setApplyInput(question.implementation);
  };

  const handleRecommendationClick = (recommendation) => {
    setApplyInput(recommendation.recommendation);
  };

  const handleRemoveRecommendation = async (recommendation, index) => {
    const dataPrep = {
      team_id: activeWorkspace?.team_id,
      req:{ 
        preprocessor_id: preprocessor.id,
        version_id: preprocessor.version,
        index
      }
    };
    setActiveRecommendations((prev) => {
      return prev.filter((item) => item !== recommendation);
    });
    await dropDataprepMutation([dataPrep]);
    queryClient.invalidateQueries(QUERY_CONSTANTS.PIPE_LINE);

    // Remove corresponding summary metrics and reindex
    setSummaryMetrics((prevMetrics) => {
      const newMetrics = { ...prevMetrics };
      delete newMetrics[index + 1]; // Remove the step corresponding to the recommendation index

      // Reindex the remaining metrics
      const reindexedMetrics = {};
      Object.keys(newMetrics)
        .sort((a, b) => a - b)
        .forEach((key, i) => {
          reindexedMetrics[i + 1] = newMetrics[key];
        });

      return reindexedMetrics;
    });
  };

  const handleRemoveActiveFeatureEngineering = async (recommendation, index) => {
    const dataPrep = {
      team_id: activeWorkspace?.team_id,
      req:{ 
        preprocessor_id: preprocessor.id,
        version_id: preprocessor.version,
        index
      }
    };

    setActiveFeatureEngineering((prev) => {
      return prev.filter((item) => item !== recommendation);
    });
    await dropDataprepMutation([dataPrep]);
    queryClient.invalidateQueries(QUERY_CONSTANTS.PIPE_LINE);

    // Remove corresponding summary metrics and reindex
    setSummaryMetrics((prevMetrics) => {
      const newMetrics = { ...prevMetrics };
      delete newMetrics[index + 1]; // Remove the step corresponding to the recommendation index

      // Reindex the remaining metrics
      const reindexedMetrics = {};
      Object.keys(newMetrics)
        .sort((a, b) => a - b)
        .forEach((key, i) => {
          reindexedMetrics[i + 1] = newMetrics[key];
        });

      return reindexedMetrics;
    });
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  //Swith statement to update text in the Recommendations section
  const recommendationText = {
    2: "Data Prep Recommendations",
    3: "Feature Engineering Recommendations",
    4: "Model Training"
  };

  const handleDataPrep = async () => {
    setIsApplyLoading(true);
    const payload = JSON.stringify(
      {
        team_id: activeWorkspace.team_id,
        organisation_id: activeWorkspace.organisation_id,
        req:{
      preprocessor_id: preprocessor.id,
      version_id: preprocessor.version,
      apply: applyInput,
      team_id: activeWorkspace?.team_id,
      organisation_id: activeWorkspace?.organisation_id,
    }});

    try {
      const response = await applyStepMutation([payload]);

      if (response.status === 200) {
        showSuccessToast("Data preparation applied successfully");
        setPreprocessor({
          id: response.data.preprocessor_id,
          version: response.data.version_id,
        });

        // Merge new summary metrics with existing ones based on the next chronological step count
        setSummaryMetrics((prevMetrics) => {
          const nextStep = Object.keys(prevMetrics).length + 1;
          return {
            ...prevMetrics,
            [nextStep]: response.data.summaryMetrics,
          };
        });

        if (!activeRecommendations.includes(applyInput) && activeStep === 2) {
          setActiveRecommendations((prevState) => [...prevState, applyInput]);
        }

        if (!activeFeatureEngineering.includes(applyInput) && activeStep === 3) {
          setActiveFeatureEngineering((prevState) => [...prevState, applyInput]);
        }

        setApplyInput("");

        queryClient.invalidateQueries(QUERY_CONSTANTS.PIPE_LINE);
        setIsApplyLoading(false);
      } else {
        showErrorToast(`Error: Received status code ${response.status}`);
        setIsApplyLoading(false);
      }
    } catch (error) {
      console.error("API call error:", error);
      showErrorToast(error?.response?.data?.detail || "Error", 5000);
      setIsApplyLoading(false);
    }
  };

  const handleTrainButton = async () => {
    const dropColumns = checkedState
      .map((checked, index) => (!checked ? titlesArray[index] : null))
      .filter((item) => item);

    const payload = JSON.stringify({
      team_id: activeWorkspace.team_id,
      req: {
      label: selectedPredictionLabel,
      partition_column: partition,
      drop_columns: dropColumns,
      model_name: modelInfo.modelName,
      model_description: modelInfo.modelDescription,
      preprocessor_id: preprocessor.id,
      version_id: preprocessor.version,
    }});

    try {
      const trainDataResponse = await trainMutation([payload]);

      // Check for the HTTP status code
      if (trainDataResponse.status !== 200) {
        showErrorToast(`Error: Received status code ${trainDataResponse.status}`);
        return;
      }

      // Additional debug log before navigation
      console.log("Navigating to /models");

      // Navigate to the models page on successful response
      navigate("/models");
    } catch (error) {
      showErrorToast(error?.response?.data?.message || "Error", 5000);
    }
  };

  return (
    <XBox display="flex" flexDirection="column" gap="12px">
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller.darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XTypography fontSize="16px" fontWeight="bold">
              Create Model
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>

          <XTypography variant="overline">Name</XTypography>
          <XInput
            placeholder="Enter your name..."
            value={modelInfo.name}
            onChange={(e) => setModelInfo({ ...modelInfo, modelName: e.target.value })}
          />

          <XTypography variant="overline">Description</XTypography>
          <XInput
            value={modelInfo.description}
            placeholder="Enter your description..."
            multiline
            rows={5}
            onChange={(e) => setModelInfo({ ...modelInfo, modelDescription: e.target.value })}
          />

          <XButton
            variant="gradient"
            color="button"
            onClick={handleTrainButton}
            sx={{ marginTop: "8px", alignSelf: "flex-end" }}
          >
            Train Model
          </XButton>
        </XBox>
      </Modal>

      {activeStep !== 4 && (
        <>
          {activeRecommendations.length > 0 ? (
            <XBox
              sx={{
                display: "flex",
                overflowX: "scroll",
                gap: "8px",
                paddingBottom: "8px",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none", // IE and Edge
                "scrollbar-width": "none", // Firefox
              }}
            >
              {activeRecommendations.map((item, index) => (
                <XBox
                  key={index}
                  sx={{
                    background: darkMode ? "#262525" : "#EAEAEA",
                    border: "1px solid #AFAFAF",
                    borderRadius: "100px",
                    display: "flex",
                    gap: "8px",
                    padding: "6px 12px",
                    alignItems: "center",
                    minWidth: "fit-content",
                  }}
                >
                  <XBox display="flex" gap="4px">
                    <XTypography
                      sx={{
                        borderRight: "1px solid #AFAFAF",
                        paddingRight: "4px",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {index + 1}
                    </XTypography>
                    <XTypography sx={{ fontSize: "12px", fontWeight: "600" }}>{item}</XTypography>
                  </XBox>

                  <Icon
                    sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                      fontSize: `${size.md} !important`,
                      fontWeight: `${fontWeightBold} !important`,
                      color: controller.darkMode ? white.main : dark.main,
                      stroke: controller.darkMode ? white.main : dark.main,
                      strokeWidth: "2px",
                      cursor: "pointer",
                    })}
                    onClick={() => {
                      const currentIndex = index;
                      handleRemoveRecommendation(item, currentIndex);
                    }}
                  >
                    close
                  </Icon>
                </XBox>
              ))}
            </XBox>
          ) : (
            <XTypography
              sx={{ color: "#7C7C7C", fontWeight: "bold", fontStyle: "italic", fontSize: "14px" }}
            >
              No Data Preparation applied
            </XTypography>
          )}
        </>
      )}

      {activeStep === 3 && (
        <>
          {activeFeatureEngineering.length > 0 ? (
            <XBox
              sx={{
                display: "flex",
                overflowX: "scroll",
                gap: "8px",
                paddingBottom: "8px",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none", // IE and Edge
                "scrollbar-width": "none", // Firefox
              }}
            >
              {activeFeatureEngineering.map((item, index) => (
                <XBox
                  key={index}
                  sx={{
                    background: darkMode ? "#262525" : rgba(colors.xppink.main, 0.2),
                    border: `1px solid ${colors.xppink.main}`,
                    borderRadius: "100px",
                    display: "flex",
                    gap: "8px",
                    padding: "6px 12px",
                    alignItems: "center",
                    minWidth: "fit-content",
                    maxWidth: "400px",
                  }}
                >
                  <XBox
                    display="flex"
                    gap="4px"
                    sx={{
                      maxWidth: "300px",
                    }}
                  >
                    <XTypography
                      color="xppink"
                      sx={{
                        borderRight: "1px solid #AFAFAF",
                        paddingRight: "4px",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {activeRecommendations.length + index + 1}
                    </XTypography>
                    <XBox display="flex" flexDirection="column" gap="4px">
                      <XTypography
                        color="xppink"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "600",
                          whiteSpace: "nowrap",
                          width: "300px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {item}
                      </XTypography>
                    </XBox>
                  </XBox>

                  <Icon
                    sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                      fontSize: `${size.md} !important`,
                      fontWeight: `${fontWeightBold} !important`,
                      color: controller.darkMode ? white.main : colors.xppink.main,
                      stroke: controller.darkMode ? white.main : colors.xppink.main,
                      strokeWidth: "2px",
                      cursor: "pointer",
                    })}
                    onClick={() => {
                      const currentIndex = activeRecommendations.length + index;
                      handleRemoveActiveFeatureEngineering(item, currentIndex);
                    }}
                  >
                    close
                  </Icon>
                </XBox>
              ))}
            </XBox>
          ) : (
            <XTypography
              sx={{ color: "#7C7C7C", fontWeight: "bold", fontStyle: "italic", fontSize: "14px" }}
            >
              No Feature Engineering applied
            </XTypography>
          )}
        </>
      )}

      <Card sx={{ padding: "16px", display: "flex", flexDirection: "column", gap: "16px" }}>
        {(activeStep === 2 || activeStep === 3) && (
          <XBox
            display="flex"
            justifyContent="space-between"
            onClick={() => {
              setIsSuggestionVisible((prevState) => !prevState);
            }}
            sx={{ cursor: "pointer" }}
          >
            <XBox display="flex">
              <XTypography fontSize="14px" color="secondary" sx={{ mr: "1" }}>
                {recommendationText[activeStep]}
              </XTypography>
            </XBox>
            <XBox display="flex">
              <XImg>{isSuggestionVisible ? <ChevronDownIcon /> : <ChevronUpIcon />}</XImg>
            </XBox>
          </XBox>
        )}

        {(activeStep === 2 || activeStep === 3) && (
          <Collapse in={isSuggestionVisible}>
            <XBox display="flex" flexDirection="column" gap="6px">
              {activeStep === 2 && (
                <XBox display="flex" flexDirection="column" gap="6px">
                  <XBox sx={{ display: "flex", overflowX: "scroll", gap: "8px" }}>
                    {recommendations.map((recommendation, index) => (
                      <XBox
                        sx={{
                          minWidth: "220px",
                          padding: 1.5,
                          borderRadius: "12px",
                          display: "flex",
                          gap: "4px",
                          cursor: "pointer",
                          border: "1px dashed #AFAFAF",
                          alignItems: "flex-start",
                          fontSize: "12px",
                          background: darkMode ? "#262525" : "white",
                        }}
                        key={index}
                        onClick={() => handleRecommendationClick(recommendation)}
                      >
                        <XTypography
                          sx={{
                            borderRight: "1px solid #DE4188",
                            paddingRight: "4px",
                            fontSize: "12px",
                            color: "#DE4188",
                            fontWeight: "600",
                          }}
                        >
                          {index}
                        </XTypography>
                        <XTypography sx={{ fontSize: "12px", fontWeight: "600" }}>
                          {recommendation.recommendation}
                        </XTypography>
                      </XBox>
                    ))}
                  </XBox>
                </XBox>
              )}

              {activeStep === 3 && (
                <XBox
                  sx={{
                    display: "flex",
                    overflowX: "scroll",
                    gap: 2,
                    p: "0 0 16px 0",
                  }}
                >
                  {isDataLoading && !featureEngineeringData?.result?.length ? (
                    <XBox
                      sx={{
                        display: "flex",
                        gap: 2,
                      }}
                    >
                      {[1, 2, 3].map((index) => (
                        <XBox
                          key={index}
                          sx={{
                            minWidth: "280px",
                            padding: 2,
                            borderRadius: "12px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <Skeleton variant="text" width="80%" height={20} />
                          <Skeleton variant="text" width="100%" height={20} sx={{ bgcolor: colors.xppink.main }} />
                          <Skeleton variant="text" width="90%" height={40} />
                        </XBox>
                      ))}
                    </XBox>
                  ) : (
                    featureEngineeringData?.result?.map((item, index) => (
                      <XBox
                        key={index}
                        sx={{
                          minWidth: "280px",
                          background: activeFeatureEngineering.includes(item.recommendation)
                            ? rgba(colors.xppink.main, 0.05)
                            : darkMode
                            ? "#262525"
                            : "white",

                          padding: 2,
                          borderRadius: "12px",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          border: "1px dashed #AFAFAF",
                          gap: "8px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActiveFeature({
                            implementation: item.implementation,
                            recommendation: item.recommendation,
                          });
                        }}
                      >
                        <XBox display="flex" gap={1}>
                          <XTypography fontSize="12px">{item.recommendation}</XTypography>
                        </XBox>

                        <XTypography sx={{ color: colors.xppink.main }} fontSize="12px">
                          {item.implementation}
                        </XTypography>

                        <XTypography color="secondary" fontSize="12px">
                          {item.rationale}
                        </XTypography>
                      </XBox>
                    ))
                  )}
                </XBox>
              )}
            </XBox>
          </Collapse>
        )}

        <XBox display="flex" width="100%" gap="12px" alignItems="center">
          {activeStep !== 4 && (
            <>
              <XInput
                sx={{ width: "100%", padding: "16px" }}
                placeholder="e.g., 'Fill missing values in age column with mean'"
                size="large"
                disabled={isApplyLoading}
                endAdornment={
                  <XBox sx={{ cursor: "pointer", display: "flex" }}>
                    {isApplyLoading || isPipeLineLoading ? (
                      <LoadingSpinner size={20} />
                    ) : (
                      <SendIcon onClick={() => handleDataPrep()} />
                    )}
                  </XBox>
                }
                value={applyInput}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleDataPrep();
                  }
                }}
                onChange={(e) => setApplyInput(e.target.value)}
              />
              <XBox sx={{ height: "40px", width: "2px", backgroundColor: "#AFAFAF" }} />
            </>
          )}
          <XBox
            display="flex"
            justifyContent="space-between"
            gap={1}
            width={activeStep === 4 ? "100%" : "auto"}
          >
            {activeStep !== 2 && (
              <XTypography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderRadius: "0.5rem",
                  transition: "all 0.3s",
                  padding: "8px",
                  "&:hover": {
                    boxShadow:
                      "0rem 0.4375rem 0.875rem 0rem rgba(13, 12, 12, 0.1), 0rem 0.1875rem 0.375rem 0rem rgba(0, 0, 0, 0.08)",
                    transform: "translateY(-1px)",
                  },
                }}
                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                fontSize="18px"
              >
                Previous
              </XTypography>
            )}

            <XButton
              variant="gradient"
              color="button"
              sx={{ whiteSpace: "nowrap", fontSize: "18px" }}
              onClick={() => {
                if (activeStep === 4) {
                  setIsModalOpen(true);
                  return;
                }
                setActiveStep((prevStep) => prevStep + 1);
              }}
            >
              {activeStep === 4 ? "Create Model" : "Next Step"}
            </XButton>
          </XBox>
        </XBox>
      </Card>
    </XBox>
  );
};

FooterBlock.propTypes = {};
