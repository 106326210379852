import XBox from "components/XBox";
import XInput from "components/XInput";

import { ReactComponent as SendIcon } from "assets/images/icons/send-icon.svg";
import { useAutoTrain } from "hooks";

export const ChatInput = () => {
  const {
    activeStep,
    setActiveGoal,
    setInputValue,
    inputValue,
  } = useAutoTrain();

  const handleSendMessage = (e) => {
    setActiveGoal(inputValue);
    setInputValue("");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <XBox display="flex" width="100%" gap="12px" alignItems="center">
      {activeStep > 1 && (
        <>
          <XInput
            sx={{ width: "100%", padding: "16px" }}
            placeholder="What would you like to visualise?"
            size="large"
            endAdornment={
              <XBox sx={{ cursor: "pointer", display: "flex" }} onClick={handleSendMessage}>
                <SendIcon />
              </XBox>
            }
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
        </>
      )}
    </XBox>
  );
};
