import { Skeleton } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { useXplainableController } from "context";
import { useAutoTrain } from "hooks";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'

import { ReactComponent as ExpandIcon } from "assets/images/icons/train/expand-icon-analyst.svg";
import { ReactComponent as NarrowIcon } from "assets/images/icons/train/narrow-icon.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/train/close-icon.svg";
import { ReactComponent as ChevronUpIcon } from "assets/images/icons/train/chevron-up-icon.svg";
import { ReactComponent as ChevronDownIcon } from "assets/images/icons/train/chevron-down-icon.svg";
import { ReactComponent as SendIcon } from "assets/images/icons/send-icon.svg";

import { ChatAutoTrain } from "../ChatAutoTrain";
import { SavedPlots } from "../SavedPlots";
import { XImg } from "components/XImg";
import XInput from "components/XInput";
import XButton from "components/XButton";
import { GoalItem } from "../GoalItem";
import colors from "assets/theme/base/colors";
import { useAutoTrainContent } from "../AutoTrainContent/useAutoTrainContent";
import { AnalystSkeletonLoader } from "shared/LoadingElements/AnalystCardSkeleton";

export const AnalystBlock = ({modelSettings}) => {
  const [isAnalystExpanded, setIsAnalystExpanded] = useState(false);
  const [isOpenSavedPlots, setIsOpenSavedPlots] = useState(false);
  const [controller] = useXplainableController();
  const { miniSidenav, darkMode } = controller;

  const {
    goalData,
    setActiveGoal,
    inputValue,
    setInputValue,
    isChatSuggestionVisible,
    setIsChatSuggestionVisible,
    setIsAnalystOpen,
  } = useAutoTrain();

  const {
    fetchGoals,
    isGoalLoading
  } = useAutoTrainContent(modelSettings);

  const handleSendMessage = (e) => {
    setActiveGoal(inputValue);
    setInputValue("");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCloseAnalyst = () => {
    if (isOpenSavedPlots) {
      setIsOpenSavedPlots(false);
      return;
    }

    setIsAnalystOpen(false);
  };

  useEffect(() => {
    fetchGoals();
  }, []);

  return (
    <XBox
      sx={({ transitions, functions: { pxToRem } }) => ({
        padding: "30px 24px",
        transition: transitions.create(["width"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        background: darkMode ? colors.background.dark : colors.white.main,
        display: "flex",
        flexDirection: "column",
        width: !isAnalystExpanded
          ? "640px"
          : miniSidenav
          ? `calc(100% - ${pxToRem(180)})`
          : `calc(100% - ${pxToRem(330)})`,
        zIndex: "100",
      })}
    >
      <XBox sx={{ display: "flex", justifyContent: "space-between" }}>
        <XTypography sx={{ fontWeight: 700 }}>
          {!isOpenSavedPlots ? "Analyst" : "Saved plots"}
        </XTypography>
        <XBox sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
          {!isOpenSavedPlots && (
            <>
              <XButton
                onClick={() => setIsOpenSavedPlots(true)}
              >
                View saved plots
              </XButton>
              <XBox
                onClick={() => setIsAnalystExpanded((prev) => !prev)}
                sx={{ marginTop: "5px", cursor: "pointer", stroke: darkMode ? "white" : "black" }}
              >
                {isAnalystExpanded ? <NarrowIcon /> : <ExpandIcon />}
              </XBox>
            </>
          )}
          <XBox
            sx={{ cursor: "pointer", stroke: darkMode ? "white" : "black" }}
            onClick={handleCloseAnalyst}
          >
            <CloseIcon />
          </XBox>
        </XBox>
      </XBox>
      {isOpenSavedPlots && <SavedPlots />}
      {!isOpenSavedPlots && <ChatAutoTrain isAnalystExpanded={isAnalystExpanded} modelSettings={modelSettings}/>}
      {!isOpenSavedPlots && (
        <XBox sx={{ display: "flex", flexDirection: "column", gap: "8px", padding: "8px" }}>
          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <XTypography fontSize="14px" color="secondary">
              Analyst Recommendations
            </XTypography>
            <XBox display="flex" onClick={() => setIsChatSuggestionVisible((prev) => !prev)}>
              <XImg>{isChatSuggestionVisible ? <ChevronDownIcon /> : <ChevronUpIcon />}</XImg>
            </XBox>
          </XBox>
          {isChatSuggestionVisible &&
            (isGoalLoading ? (
              <AnalystSkeletonLoader count={3} />
            ) : (
              <XBox
                sx={{
                  display: "flex",
                  overflowX: "scroll",
                  gap: 2,
                  p: "0 0 16px 0",
                }}
              >
                {goalData.map((item, index) => (
                  <GoalItem item={item} key={index} />
                ))}
              </XBox>
            ))}
          <XBox display="flex" width="100%" gap="12px" alignItems="center">
              <>
                <XInput
                  sx={{ width: "100%", padding: "16px" }}
                  placeholder="Enter prompt here"
                  size="large"
                  endAdornment={
                    <XBox sx={{ cursor: "pointer", display: "flex" }} onClick={handleSendMessage}>
                      <SendIcon />
                    </XBox>
                  }
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                />
              </>
          </XBox>
        </XBox>
      )}
    </XBox>
  );
};


AnalystBlock.propTypes = {
  modelSettings: PropTypes.object.isRequired,
};