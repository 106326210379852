import { Card, Collapse } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { ChatAutoTrain, GoalItem } from "layouts/autotrain/components";
import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { ReactComponent as ChevronUpIcon } from "assets/images/icons/train/chevron-up-icon.svg";
import { ReactComponent as ChevronDownIcon } from "assets/images/icons/train/chevron-down-icon.svg";
import { XImg } from "components/XImg";
import { useAutoTrain } from "hooks";
import { useXplainableController } from "context";
import { useAutoTrainContent } from "layouts/autotrain/components/AutoTrainContent/useAutoTrainContent";
import { ChatInput } from "layouts/autotrain/components";
import { AnalystSkeletonLoader } from "shared/LoadingElements/AnalystCardSkeleton";

export const AnalystChat = ({
  modelSettings,
  uploadSuccessful,
  activeStep,
}) => {
  const { isChatSuggestionVisible, setIsChatSuggestionVisible, goalData } = useAutoTrain();
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const { fetchGoals, isGoalLoading } = useAutoTrainContent(modelSettings);

  useEffect(() => {
    fetchGoals();
  }, []);


  // Show chat input when analysis is complete (after step 2)
  const showChatInput = uploadSuccessful && activeStep > 1;
  return (
    <Card sx={{ p: 3 }}>
      <XBox
        sx={{
          height: "calc(100vh - 375px)",
          backgroundColor: darkMode ? "#262525" : "#FFF",
          borderRadius: "12px",
        }}
      >
        <ChatAutoTrain isAnalyst />
      </XBox>

      <XBox
        sx={{
          border: "1px solid #EAEAEA",
          borderRadius: "16px",
          padding: 2
        }}
      >
        <XBox 
          display="flex" 
          justifyContent="space-between" 
          alignItems="center"
          onClick={() => setIsChatSuggestionVisible((prev) => !prev)}
          sx={{ cursor: "pointer" }}
        >
          <XTypography fontSize="14px" color="black" fontWeight="bold">
            Analyst Recommendations
          </XTypography>
          <XBox display="flex">
            <XImg>
              {isChatSuggestionVisible ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </XImg>
          </XBox>
        </XBox>

        <Collapse 
          in={isChatSuggestionVisible} 
          timeout={{ enter: 500, exit: 500 }}
          sx={{ mt: 1 }}
        >
          <XBox
            sx={{
              display: "flex",
              overflowX: "scroll",
              gap: 2,
              maxHeight: "300px",
              p: "0 0 16px 0",
            }}
          >
            {isGoalLoading ? (
              <AnalystSkeletonLoader count={3} />
            ) : (
              goalData.map((item, index) => (
                <GoalItem item={item} key={index} />
              ))
            )}
          </XBox>
        </Collapse> 
        {showChatInput && 
        <XBox mt={1}>
          <ChatInput />
        </XBox>} 
      </XBox>
    </Card>
  );
};

AnalystChat.propTypes = {
  modelSettings: PropTypes.object.isRequired,
  uploadSuccessful: PropTypes.bool.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
};
