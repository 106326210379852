import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import XBox from "components/XBox";

export const VisualisationSkeletonLoader = ({ darkMode, colors }) => {
  return (
    <XBox sx={{ px: 6, py: 3 }}>
      <XBox mb={2} display="flex" alignItems="center" gap={1}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" width={100} height={24} />
      </XBox>
      <XBox sx={{ px: 6, py: 3 }}>
        <XBox mb={3} sx={{ bgcolor: darkMode ? colors.background.dark : colors.background.default, borderRadius: 2 }}>
            <Skeleton variant="text" width="40%" height={32} />
            <Skeleton variant="text" width="80%" height={24} />
        </XBox>
        <XBox sx={{ bgcolor: darkMode ? colors.background.dark : colors.background.default, borderRadius: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={400} />
        </XBox>
        
        <XBox mt={2} display="flex" gap={2} >
            <Skeleton variant="rectangular" width={80} height={40} />
            <Skeleton variant="rectangular" width={80} height={40} />
        </XBox>
      </XBox>
    </XBox>
  );
};

VisualisationSkeletonLoader.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  colors: PropTypes.shape({
    background: PropTypes.shape({
      dark: PropTypes.string,
      default: PropTypes.string
    })
  }).isRequired
};

export default VisualisationSkeletonLoader;