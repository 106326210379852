import React from "react";
import { animateGradient } from "assets/theme/base/animations";
import { useXplainableController } from "context";
import {
  AnalystAppBar,
  AnalystBlock,
  AutoTrainContent,
  SettingTab,
} from "./components";
import { useAutoTrain, useSettingTab } from "hooks";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReuseableHeader from "shared/Headers/ReuseableHeader";

import XBox from "components/XBox";

export const AutoTrain = () => {
  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;
  const { isAnalystOpen, tabValue } = useAutoTrain();

  const {
    isModalOpen,
    handleClose,
    modelSettings,
    modelHandler,
    modelProviderHandler,
    maxTokenHandler,
    temperatureHandler,
    frequencyPenaltyHandler,
    presencePenaltyHandler,
    topPHandler,
    streamHandler,
    stopHandler,
    nSlideHandler
  } = useSettingTab();


  return (
    <DashboardLayout
      sx={{
        backgroundImage: () =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title="AutoTrain"
        description={"One-click shareable reports with intuitive drag-and-drop functionality"}
        icon={"ni ni-atom"}
      />

      <AnalystAppBar />

      {isAnalystOpen && (
        <AnalystBlock modelSettings={modelSettings}/>
      )}

      <AutoTrainContent modelSettings={modelSettings}/>

      <XBox>
        {tabValue === 1 && (
          <SettingTab
            isModalOpen={isModalOpen}
            handleClose={handleClose}
            modelSettings={modelSettings}
            modelProviderHandler={modelProviderHandler}
            modelHandler={modelHandler}
            maxTokenHandler={maxTokenHandler}
            temperatureHandler={temperatureHandler}
            frequencyPenaltyHandler={frequencyPenaltyHandler}
            presencePenaltyHandler={presencePenaltyHandler}
            topPHandler={topPHandler}
            streamHandler={streamHandler}
            stopHandler={stopHandler}
            nSlideHandler={nSlideHandler}
          />
        )}
      </XBox>
    </DashboardLayout>
  );
};
