import React from "react";
import { Card, Step, StepLabel, Stepper } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import colors from "assets/theme/base/colors";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { UploadDataset } from "../UploadDataset";
import { DataTable } from "../DataTable";
import { FooterBlock } from "../FooterBlock";
import { Train } from "../Train";
import { useAutoTrainContent } from "./useAutoTrainContent";
import PropTypes from 'prop-types';

const STEPS = [
  "Upload File",
  "Analysing Features",
  "Data Prep",
  "Feature Engineering",
  "Model Training",
];

export const AutoTrainContent = ({ modelSettings }) => {
  const {
    tabValue,
    uploadSuccessful,
    isLoading,
    uploadProgress,
    fileNames,
    isDataLoading,
    fileMetadata,
    activeStep,
    getRootProps,
    getInputProps,
    onDropAccepted,
    setDataSetName,
  } = useAutoTrainContent(modelSettings);

  return (
    <XBox>
      {tabValue === 0 && (
        <>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              marginTop: 1,
              marginBottom: 2,
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {STEPS.map((label, index) => (
              <Step
                key={label}
                sx={{
                  "& .Mui-active": {
                    color: `${colors.xppink.main} !important`,
                    "@keyframes flash": {
                      "0%": { filter: 'brightness(1)' },
                      "50%": { filter: 'brightness(0.7)' },
                      "100%": { filter: 'brightness(1)' }
                    },
                    animation: index === activeStep ? "flash 2s infinite" : "none",
                  },
                  "& .Mui-completed": {
                    fill: `${colors.xppink.main} !important`,
                    stroke: `${colors.xppink.main} !important`,
                    borderColor: `${colors.xppink.main} !important`,
                    color: `${colors.xppink.main} !important`,
                  },
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {!uploadSuccessful && !isLoading && (
            <Card sx={{ padding: "16px", height: "100vh%" }}>
              <UploadDataset
                fileNames={fileNames}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                onDropAccepted={onDropAccepted}
                setDataSetName={setDataSetName}
              />
            </Card>
          )}

          {uploadProgress && fileNames.length > 0 ? (
           <DataTable fileMetadata={fileMetadata} />
          ) : uploadSuccessful ? (
            <XBox display="flex" flexDirection="column" gap={3}>
              {isDataLoading && activeStep === 0 ? (
                <XBox
                  sx={{
                    height: "500px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <LoadingSpinner animationType="pulse" size={50} />
                </XBox>
              ) : (
                <>
                  {activeStep === 2 && <DataTable fileMetadata={fileMetadata} />}
                  {activeStep === 3 && <DataTable fileMetadata={fileMetadata} />}
                  {activeStep === 4 && <Train fileMetadata={fileMetadata} />}
                  <FooterBlock />
                </>
              )}
            </XBox>
          ) : (
            <div></div>
            // <Card 
            //   sx={{ 
            //     padding: "16px", 
            //     height: "500px", 
            //     display: "flex", 
            //     alignItems: "center",
            //     justifyContent: "center",
            //     flexDirection: "column",
            //     gap: 2
            //   }}
            // >
            //   <XTypography 
            //     variant="h5" 
            //     color="error"
            //     sx={{ fontWeight: "bold" }}
            //   >
            //     An Error Has Occurred
            //   </XTypography>
            //   <XTypography color="secondary">
            //     Please try uploading your file again
            //   </XTypography>
            // </Card>
          )}
        </>
      )}
    </XBox>
  );
};

AutoTrainContent.propTypes = {
  modelSettings: PropTypes.object.isRequired,
};
