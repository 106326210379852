import { Skeleton } from "@mui/material";
import XBox from "components/XBox";
import PropTypes from 'prop-types';

export const AnalystSkeletonLoader = ({ count = 3 }) => {
  return (
    <XBox
      sx={{
        display: "flex",
        overflowX: "scroll",
        gap: 2,
        p: "0 0 16px 0",
      }}
    >
      {Array.from({ length: count }).map((_, index) => (
        <XBox
          key={index}
          sx={{
            minWidth: "300px",
            p: 3,
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 2,
          }}
        >
          <Skeleton variant="text" width="80%" height={32} />
          <Skeleton variant="text" width="60%" height={24} sx={{ mt: 2 }} />
          <Skeleton variant="text" width="40%" height={24} sx={{ mt: 2 }} />
        </XBox>
      ))}
    </XBox>
  );
};

AnalystSkeletonLoader.propTypes = {
  count: PropTypes.number
};