import { Stepper, Step, StepLabel } from "@mui/material";
import XBox from "components/XBox";
import React from "react";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";
import { DataTable } from "../DataTable";

const LOADING_STEPS = [
  "Uploading File",
  "Analysing Content",
  "Chat with your data"
];

export const ProgressBlock = ({ activeStep }) => {
  return (
    <XBox>
      <XBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: 1,
          height: "80px",
        }}
      >
        <XBox sx={{ width: "100%", marginY: 2 }}>
          <Stepper 
            activeStep={activeStep} 
            alternativeLabel
            sx={{
              marginTop: 1,
              marginBottom: 2,
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {LOADING_STEPS.map((label, index) => (
              <Step
                key={label}
                sx={{
                  "& .Mui-active": {
                    color: `${colors.xppink.main} !important`,
                    "@keyframes flash": {
                      "0%": { filter: 'brightness(1)' },
                      "50%": { filter: 'brightness(0.7)' },
                      "100%": { filter: 'brightness(1)' }
                    },
                    animation: index === activeStep ? "flash 2s infinite" : "none",
                  },
                  "& .Mui-completed": {
                    color: `${colors.xppink.main} !important`,
                  },
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </XBox>
      </XBox>

    </XBox>
  );
};

ProgressBlock.propTypes = {
  activeStep: PropTypes.number,
  fileMetadata: PropTypes.array,
};
