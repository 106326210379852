import { useState, useMemo, useEffect } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// @mui material components
import { Grid, Card, AppBar, Tabs, Tab } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";

// xplainable dashboard base styles
import breakpoints from "assets/theme/base/breakpoints";

import Networking from "./components/networking";
import DeploymentSelection from "./components/DeploymentSelection";
import FeatureRequest from "shared/Cards/FeatureRequest";
import { Output } from "./components/Output";
import { useXplainableController } from "context";

function DeploymentConfig() {
  const [controller] = useXplainableController();
  const [tabValue, setTabValue] = useState(0);
  const [collapsedWidth, setCollapsedWidth] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const toggleCollapse = () => {
    setCollapsedWidth((prev) => !prev);
  };

  return (
    <XBox mt={4}>
      <Grid container zIndex={1} spacing={3}>
        <Grid item xs={12} zIndex={1} lg={12}>
          <Card sx={{ padding: "16px" }}>
            <XBox display="flex" mb={4}>
              <XBox>
                <AppBar position="static">
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                    sx={{
                      backgroundColor: `${controller.darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,

                      transition: "all 500ms ease",
                      color: "#AFAFAF",

                      "& .Mui-selected": {
                        fontWeight: "600",
                        color: `${controller.darkMode ? "white" : "black"} !important`,
                        backgroundColor: `${controller.darkMode ? "#262525" : "white"} !important`,
                      },
                      "& .MuiTabs-indicator": {
                        display: "none",
                      },
                    }}
                  >
                    {/* <Tab label="Model Selection" sx={{ minWidth: 0 }} /> */}
                    <Tab label="Networking" sx={{ minWidth: 0 }} />
                    <Tab label="Output" sx={{ minWidth: 0 }} />
                  </Tabs>
                </AppBar>
              </XBox>
            </XBox>
            <XBox>
              {/* {tabValue === 0 && <DeploymentSelection />} */}
              {/* {tabValue === 0 && <Output />} */}
              {tabValue === 0 && <Networking />}
              {tabValue === 1 && <DeploymentSelection />} 
            </XBox>
          </Card>
        </Grid>
      </Grid>
    </XBox>
  );
}

export default DeploymentConfig;
