// import { useQuery } from "@tanstack/react-query";
// import { apiHandlers } from "api/apiHandlers";
// import { QUERY_CONSTANTS } from "constants";

// export const useCommentsQuery = ({
//   activeWorkspace,
//   modelId,
//   deploymentId,
//   collectionId,
//   type,
//   pageNumber,
//   itemsPerPage,
//   logout,
// }) => {
//   if (type === "models") {
//     return useQuery(
//       [QUERY_CONSTANTS.COMMENTS_MODELS, pageNumber, itemsPerPage, activeWorkspace, modelId],
//       () =>
//         apiHandlers.comments
//           .getCommentsModels(activeWorkspace, modelId, pageNumber, itemsPerPage)
//           .then((res) => res),
//       {
//         onError: (err) => {
//           console.log("The comments error is", err)
//           // handleTokenExpired(err, logout);
//         },
//       }
//     );
//   }

//   if (type === "deployments") {
//     return useQuery(
//       [
//         QUERY_CONSTANTS.COMMENTS_DEPLOYMENTS,
//         pageNumber,
//         itemsPerPage,
//         activeWorkspace,
//         deploymentId,
//       ],
//       () =>
//         apiHandlers.comments
//           .getCommentsDeployments(activeWorkspace, deploymentId, pageNumber, itemsPerPage)
//           .then((res) => res),
//       {
//         onError: (err) => {
//           console.log("The comments error is", err)
//           // handleTokenExpired(err, logout);
//         },
//       }
//     );
//   }

//   if (type === "collections") {
//     return useQuery(
//       [QUERY_CONSTANTS.COMMENTS_COLLECTIONS, pageNumber, itemsPerPage, collectionId, collectionId],
//       () =>
//         apiHandlers.comments
//           .getCommentsCollections(activeWorkspace, collectionId, pageNumber, itemsPerPage)
//           .then((res) => res),
//       {
//         onError: (err) => {
//           console.log("The comments error is", err)
//           // handleTokenExpired(err, logout);
//         },
//       }
//     );
//   }
// };

// In useCommentsQuery.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";

export const useCommentsQuery = ({
  resourceId,
  pageNumber,
  itemsPerPage,
}) => {
  return useQuery(
    [QUERY_CONSTANTS.COMMENTS, resourceId, pageNumber, itemsPerPage],
    () =>
      apiHandlers.comments
        .getComments(resourceId, pageNumber, itemsPerPage)
        .then((res) => res),
    {
      onError: (err) => {
        console.log("The comments error is", err);
        // handleTokenExpired(err, logout);
      },
    }
  );
};

