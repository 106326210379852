import { useEffect } from "react";
import { Card, Checkbox, Grid, Icon, Tooltip } from "@mui/material";
import { useXplainableController } from "context";
import { ReactComponent as MagicWandIcon } from "assets/images/icons/train/magic-wand-icon.svg";

import PropTypes from "prop-types";
import XTypography from "components/XTypography";
import XSelect from "components/XSelect";
import XBox from "components/XBox";
import { useAutoTrain } from "hooks";
import { DataTable } from "../DataTable";
import XButton from "components/XButton";

export const Train = ({ fileMetadata }) => {
  const {
    checkedState,
    setCheckedState,
    selectedPredictionLabel,
    setSelectedPredictionLabel,
    setTitlesArray,
    columnKeys,
    partition,
    setPartition,
  } = useAutoTrain();


  useEffect(() => {
    if (columnKeys.length > 0) {
      setCheckedState(columnKeys.map(() => true));
      setTitlesArray(columnKeys);
    }
  }, [fileMetadata, setCheckedState, setTitlesArray, columnKeys]);

  const handleCheckboxChange = (index) => {
    const newCheckedState = [...checkedState];
    newCheckedState[index] = !newCheckedState[index];
    setCheckedState(newCheckedState);
  };

  const handlePredictionLabelChange = (selectedOption) => {
    const selectedLabel = selectedOption?.value;

    if (selectedPredictionLabel) {
      const previousIndex = columnKeys.indexOf(selectedPredictionLabel);
      if (previousIndex !== -1 && selectedLabel !== selectedPredictionLabel) {
        // Recheck the previous label if a new label is selected or the dropdown is cleared
        setCheckedState((prevState) => {
          const newCheckedState = [...prevState];
          newCheckedState[previousIndex] = true;
          return newCheckedState;
        });
      }
    }

    if (selectedLabel) {
      setSelectedPredictionLabel(selectedLabel);

      if (partition === selectedLabel) {
        setPartition(null);
      }
    } else {
      setSelectedPredictionLabel(null);
    }
  };

  const handlePartitionColumnChange = (selectedOption) => {
    const selectedColumn = selectedOption?.value;
    setPartition(selectedColumn);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={2.5}>
        <XBox
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="space-between"
          gap={2}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "16px",
            }}
          >
            <XBox display="flex" gap={1} alignItems="center">
              <XTypography fontSize="14px" fontWeight="bold">
                Prediction Label
              </XTypography>
              <XBox mr={2}>
                <Tooltip
                  title="This is the outcome or result your model is trying to predict, like 'spam' or 'not spam'"
                  placement="top"
                >
                  <XButton variant="outlined" color="secondary" size="xs" circular iconOnly>
                    <Icon>question_mark</Icon>
                  </XButton>
                </Tooltip>
              </XBox>
            </XBox>
            <XBox display="flex" alignItems="center" gap="12px">
              <XSelect
                options={columnKeys.map((item) => ({ value: item, label: item }))}
                onChange={handlePredictionLabelChange}
                value={
                  selectedPredictionLabel
                    ? { value: selectedPredictionLabel, label: selectedPredictionLabel }
                    : null
                }
                isClearable
              />
              <XBox
                sx={{
                  padding: "7px",
                  background: "#FFEBF4",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MagicWandIcon />
              </XBox>
            </XBox>
            <XBox display="flex" gap={1} alignItems="center">
              <XTypography fontSize="14px" fontWeight="bold">
                Partition On
              </XTypography>
              <XBox mr={2}>
                <Tooltip
                  title="Choose a way to split your data into parts to build different machine learning models"
                  placement="top"
                >
                  <XButton variant="outlined" color="secondary" size="xs" circular iconOnly>
                    <Icon>question_mark</Icon>
                  </XButton>
                </Tooltip>
              </XBox>
            </XBox>
            <XBox display="flex" alignItems="center" gap="12px">
              <XSelect
                options={columnKeys
                  .filter((item) => item !== selectedPredictionLabel)
                  .map((item) => ({ value: item, label: item }))}
                onChange={handlePartitionColumnChange}
                value={
                  partition
                    ? { value: partition, label: partition }
                    : null
                }
                isClearable
              />
            </XBox>
          </Card>
          <Card
            sx={{
              maxHeight: "585px",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "16px",
            }}
          >
            <XBox display="flex" gap={1} alignItems="center">
              <XTypography fontSize="14px" fontWeight="bold">
                Columns
              </XTypography>
              <XBox mr={2}>
                <Tooltip title="Columns to include in model training" placement="top">
                  <XButton variant="outlined" color="secondary" size="xs" circular iconOnly>
                    <Icon>question_mark</Icon>
                  </XButton>
                </Tooltip>
              </XBox>
            </XBox>
            <XBox
              sx={{
                overflow: "scroll",
                display: "flex",
                flexDirection: "column",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none", // IE and Edge
                "scrollbar-width": "none", // Firefox
              }}
            >
              {checkedState.length > 0 &&
                columnKeys.map((item, index) => (
                  <XBox display="flex" alignItems="center" gap="12px" key={index} my={0.2}>
                    <Checkbox
                      checked={checkedState[index]}
                      onChange={() => handleCheckboxChange(index)}
                      sx={{
                        py: 1,
                        "&.Mui-checked": {
                          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), linear-gradient(#DE4188, #DE4188)`,
                          border: "none",
                        },
                      }}
                    />
                    <XTypography
                      fontSize="14px"
                      color={checkedState[index] ? "inherit" : "secondary"}
                    >
                      {item}
                    </XTypography>
                  </XBox>
                ))}
            </XBox>
          </Card>
        </XBox>
      </Grid>
      <Grid item xs={12} lg={9.5}>
        <DataTable fileMetadata={fileMetadata} />
      </Grid>
    </Grid>
  );
};

Train.propTypes = {
  fileMetadata: PropTypes.array,
};
