import React, { useEffect, useState } from "react";
import { useTrainMutation } from "api/mutations";
import { useToast } from "hooks";
import PropTypes from "prop-types";

import colors from "assets/theme/base/colors";
import XBox from "components/XBox";
import XSelect from "components/XSelect";
import XTypography from "components/XTypography";
import { useListModelsQuery, useLLMApiKeyQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Slider, CircularProgress, IconButton, Tooltip, Switch } from "@mui/material";
import { XImg } from "components/XImg";
import XInput from "components/XInput";

import SaveIcon from "assets/images/icons/configuration/save-icon.svg";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { ReactComponent as DarkEyeIcon } from "assets/images/icons/deployments/dark-eye-icon.svg";

export const SettingTab = ({
  modelSettings,
  modelProviderHandler,
  modelHandler,
  maxTokenHandler,
  temperatureHandler,
  frequencyPenaltyHandler,
  presencePenaltyHandler,
  topPHandler,
  streamHandler,
  stopHandler,
  nSlideHandler
}) => {
  const { logout } = useAuth0();
  const { data, isLoading } = useListModelsQuery(logout);
  const { data: llmKeyData, isLoading: isLLMKeyLoading } = useLLMApiKeyQuery(modelSettings?.provider, logout);
  const { llmKeyMutation } = useTrainMutation();
  const { showSuccessToast } = useToast();

  const [providers, setProviders] = useState([]);
  const [models, setModels] = useState([]);
  const [maxTokens, setMaxTokens] = useState(4096);
  const [modelData, setModelData] = useState({});
  const [showKey, setShowKey] = useState(false);
  const [keyValue, setKeyValue] = useState(""); 
  const [isEditingKey, setIsEditingKey] = useState(false);
  const excludedKeys = ["litellm_provider", "supports_vision", "source"];

  useEffect(() => {
    if (data?.data) {
      const providers = Object.keys(data.data);
      setProviders(providers);
    }
  }, [data]);

  useEffect(() => {
    if (data?.data && modelSettings.provider) {
      const models = Object.keys(data.data[modelSettings.provider] || {}).map(key => ({
        value: key,
        label: key,
      }));
      setModels(models);
    } else {
      setModels([]);
    }
  }, [data, modelSettings.provider]);

  useEffect(() => {
    if (data?.data && modelSettings.provider && modelSettings.model) {
      //Update logic to handle change in Litellm API calls
      const modelName = modelSettings.model.split('/').pop();
      const selectedModel = data.data[modelSettings.provider]?.[modelName];
      
      if (selectedModel) {
        setMaxTokens(selectedModel.max_tokens);
        const costPerCompletion = 
          (selectedModel.input_cost_per_token * selectedModel.max_tokens) +
          (selectedModel.output_cost_per_token * selectedModel.max_output_tokens);
        setModelData({ ...selectedModel, cost_per_full_completion: costPerCompletion });
      }
    }
  }, [data, modelSettings.provider, modelSettings.model]);

  useEffect(() => {
    if (llmKeyData) {
      setKeyValue(llmKeyData?.data || ""); // Set the initial value for the input field
    }
  }, [llmKeyData]);

  const handleProviderChange = (option) => {
    if (option) {
      modelProviderHandler(option);

      const modelsForProvider = Object.keys(data.data[option.value] || {}).map(key => ({
        value: key,
        label: key,
      }));
      setModels(modelsForProvider);

      if (modelsForProvider.length > 0) {
        const firstModel = modelsForProvider[0];
        modelHandler(firstModel); // Select the first model in the list
        const selectedModel = data.data[option.value]?.[firstModel.value];
        if (selectedModel) {
          setMaxTokens(selectedModel.max_tokens);
          maxTokenHandler({ target: { value: selectedModel.max_tokens } });
        }
      } else {
        modelHandler(null); // Reset model selection if no models available
        setMaxTokens(4096); // Reset maxTokens if no models available
      }
    }
  };

  const handleModelChange = (option) => {
    if (option) {
      modelHandler(option);
      const selectedModel = data.data[modelSettings.provider]?.[option.value];
      if (selectedModel) {
        setMaxTokens(selectedModel.max_tokens);
        maxTokenHandler({ target: { value: selectedModel.max_tokens } });
      }
    }
  };

  const handleSaveKey = async () => {
    try {
      await llmKeyMutation([{ [modelSettings.provider]: keyValue }]);
      setIsEditingKey(false);
      setShowKey(false); 
      showSuccessToast(`Successfully added the the api key for ${modelSettings.provider}.`);
    } catch (error) {
      console.error("Failed to save API key:", error);
    }
  };

  if (isLoading) {
    return (
      <XBox>
        <Card sx={{ padding: "24px", display: "flex", justifyContent: "center", alignItems: "center", height: "500px" }}>
          <CircularProgress />
        </Card>
      </XBox>
    );
  }

  return (
    <Card sx={{ padding: 4, mt: 2 }}>
      <XBox display="flex" flexDirection="column" gap={2}>
        <XBox display="flex" justifyContent="space-between" alignItems="center">
          <XTypography fontSize="18px" fontWeight="bold">
            Generation Settings
          </XTypography>
        </XBox>

        <XBox display="flex" gap={3}>
          <XBox width="50%" display="flex" flexDirection="column" gap={1}>
            <XBox>
              <XBox>
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">Model Provider</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.provider}
                  </XTypography>
                </XBox>
                <XTypography fontSize="12px" color="secondary">
                  Model provider e.g. openai, huggingface etc
                </XTypography>
              </XBox>
              <XSelect
                placeholder="Select Provider"
                defaultValue={{
                  value: modelSettings.provider,
                  label: modelSettings.provider,
                }}
                options={providers.map(provider => ({
                  value: provider,
                  label: provider,
                }))}
                onChange={(option) => handleProviderChange(option)}
              />
            </XBox>

            <XBox width="100%" display="flex" flexDirection="column">
              <XBox>
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">Model</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.model}
                  </XTypography>
                </XBox>
                <XTypography fontSize="12px" color="secondary">
                  The model to use for generation.
                </XTypography>
              </XBox>
              <XSelect
                placeholder="Select Model"
                value={{
                  value: modelSettings.model,
                  label: modelSettings.model,
                }}
                options={models}
                onChange={(option) => handleModelChange(option)}
              />
            </XBox>
            <XBox 
              width="100%" 
              display="flex" 
              flexWrap="wrap"
              mt={1} 
              p={1} 
              borderRadius="10px"
              sx={{ 
                border: '1px solid',
                borderColor: colors.light.main 
              }}
              gap={2}
            >
              {Object.entries(modelData).map(([key, value]) => {
                if (!excludedKeys.includes(key)) {
                  return (
                    <XBox 
                      key={key} 
                      display="flex" 
                      flexDirection="column" 
                      p={1}
                    >
                      <XTypography fontSize="12px" color={key === "cost_per_full_completion" ? "error" : "secondary"}>
                        {key.replace(/_/g, ' ')}
                      </XTypography>
                      <XTypography fontSize="16px" color={key === "cost_per_full_completion" ? "error" : "text"}>
                        {key === "cost_per_full_completion" ? "$" + value.toFixed(3).toString() + " USD" : value.toString()}
                      </XTypography>
                    </XBox>
                  );
                }
                return null;
              })}
            </XBox>
            <XTypography fontSize="18px" fontWeight="bold" mb={1} mt={3}>
              Provider API Key
            </XTypography>
            <XBox display="flex" flexDirection="column" p={1} borderRadius="10px" sx={{ border: '1px solid', borderColor: colors.light.main }}>
              <XTypography fontSize="14px">{modelSettings.provider}</XTypography>
              <XBox display="flex" mt={1} position="relative">
                <XBox width="100%">
                <XInput
                    type={showKey ? "text" : "password"}
                    placeholder={"Paste the provider key here..."}
                    value={keyValue}
                    onChange={(e) => {
                      setKeyValue(e.target.value);
                      setIsEditingKey(e.target.value !== "");
                      setShowKey(true)
                    }} // Update the state on change
                    sx={{ pr: 4 }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSaveKey();
                      }
                    }}
                  />
                </XBox>
                <XBox position="absolute" right="0">
                {isLLMKeyLoading ? (
                    <XBox mt={1.1} mr={1}>
                      <LoadingSpinner size={20} /> 
                    </XBox>
                  ) : isEditingKey ? (
                    <Tooltip title="Save Provider Key">
                       <XBox
                        mt={1} 
                        mr={1}
                        src={SaveIcon}
                        component="img"
                        onClick={handleSaveKey}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={showKey ? "Hide Provider Key" : "Show Provider Key"}>
                      <IconButton
                        size="medium"
                        color={"dark"}
                        onClick={() => setShowKey(!showKey)}
                      >
                        <XImg>
                          <DarkEyeIcon />
                        </XImg>
                      </IconButton>
                    </Tooltip>
                  )}
                </XBox>
              </XBox>
            </XBox>
          </XBox>
          <XBox width="50%" display="flex" flexDirection="column" gap={1}>
            <XBox display="flex" flexDirection="column">
              <XBox display="flex" flexDirection="column">
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">Max Tokens</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.max_tokens}
                  </XTypography>
                </XBox>
                <XTypography fontSize="12px" color="secondary">
                  Maximum number of tokens to generate.
                </XTypography>
              </XBox>
              <Slider
                value={modelSettings.max_tokens}
                min={0}
                max={maxTokens}
                onChange={maxTokenHandler}
              />
            </XBox>

            <XBox display="flex" flexDirection="column">
              <XBox display="flex" flexDirection="column">
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">Temperature</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.temperature}
                  </XTypography>
                </XBox>
                <XTypography fontSize="12px" color="secondary">
                  The higher the temperature, the crazier the text.
                </XTypography>
              </XBox>
              <Slider
                value={modelSettings.temperature}
                min={0}
                max={1}
                step={0.1}
                onChange={temperatureHandler}
              />
            </XBox>

            <XBox display="flex" flexDirection="column">
              <XBox display="flex" flexDirection="column">
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">Presence Penalty</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.presence_penalty}
                  </XTypography>
                </XBox>
                <XTypography fontSize="12px" color="secondary">
                  {`Number between -2.0 and 2.0. Positive values penalize new tokens based on
                      whether they appear in the text so far, increasing the model's likelihood to
                      talk about new topics.`}
                </XTypography>
              </XBox>
              <Slider
                value={modelSettings.presence_penalty}
                min={-2}
                max={2}
                step={0.1}
                onChange={presencePenaltyHandler}
              />
            </XBox>

            <XBox display="flex" flexDirection="column">
              <XBox display="flex" flexDirection="column">
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">Frequency Penalty</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.frequency_penalty}
                  </XTypography>
                </XBox>
                <XTypography fontSize="12px" color="secondary">
                  {`Number between -2.0 and 2.0. Positive values penalize new tokens based on
                      whether they appear in the text so far, increasing the model's likelihood to
                      talk about new topics.`}
                </XTypography>
              </XBox>
              <Slider
                value={modelSettings.frequency_penalty}
                min={-2}
                max={2}
                step={0.1}
                onChange={frequencyPenaltyHandler}
              />
            </XBox>

            <XBox display="flex" flexDirection="column">
              <XBox display="flex" flexDirection="column">
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">TopP</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.top_p}
                  </XTypography>
                </XBox>
                <XTypography fontSize="12px" color="secondary">
                  {`Number between 0 and 1.0.`}
                </XTypography>
              </XBox>
              <Slider
                value={modelSettings.top_p}
                min={0}
                max={1}
                step={0.1}
                onChange={topPHandler}
              />
            </XBox>
            <XBox display="flex" flexDirection="column">
              <XBox display="flex" flexDirection="column">
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">N</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.n}
                  </XTypography>
                </XBox>
                <XTypography fontSize="12px" color="secondary">
                  {`Number between 1 and 5`}
                </XTypography>
              </XBox>
              <Slider
                value={modelSettings.n}
                min={1}
                max={5}
                step={1}
                onChange={nSlideHandler}
              />
            </XBox>

            <XBox display="flex" flexDirection="row">
              <XBox display="flex" flexDirection="column" width={"80%"}>
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">Stop</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.stop}
                  </XTypography>
                </XBox>
                <XInput
                  type="text"
                  value={modelSettings.stop}
                  onChange={stopHandler}
                  sx={{ pr: 4 }}
                />
              </XBox>
              <XBox display="flex" flexDirection="column" width={"20%"} pl={2}>
                <XBox display="flex" gap="4px" alignItems="flex-end">
                  <XTypography fontSize="14px">Stream</XTypography>
                  <XTypography fontSize="12px" sx={{ color: colors.xppink.main }}>
                    {modelSettings.stream}
                  </XTypography>
                </XBox>
                <Switch checked={modelSettings.stream} onChange={streamHandler}/>
              </XBox>
            </XBox>
          </XBox>
        </XBox>
      </XBox>
    </Card>
  );
};

SettingTab.propTypes = {
  modelSettings: PropTypes.object.isRequired,
  modelProviderHandler: PropTypes.func.isRequired,
  modelHandler: PropTypes.func.isRequired,
  maxTokenHandler: PropTypes.func.isRequired,
  temperatureHandler: PropTypes.func.isRequired,
  frequencyPenaltyHandler: PropTypes.func.isRequired,
  presencePenaltyHandler: PropTypes.func.isRequired,
  topPHandler: PropTypes.func.isRequired,
  streamHandler: PropTypes.func.isRequired,
  stopHandler: PropTypes.func.isRequired,
  nSlideHandler: PropTypes.func.isRequired,
};
