import React, { useEffect, useRef, useState } from "react";

import { ClickAwayListener, Grid, Tooltip } from "@mui/material";
import { animateGradient } from "assets/theme/base/animations";
import { useXplainableController } from "context";
import { EditableBlock, ShareReport } from "./components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";
import { components } from "./constants";
import { useApp, useModel } from "hooks";
import { useReportMutation } from "api/mutations/useReportMutation";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useReportsVersionQuery } from "api/query/useReportsVersionQuery";
import { queryClient } from "api/queryClient";
import { QUERY_CONSTANTS } from "constants";
import { useUsersQuery } from "api/query";
import { XDivider } from "components/XDivider";
import { REPORT_COMPONENTS_KEYS } from "constants";
import { XImg } from "components/XImg";

import { ReactComponent as DarkEditIcon } from "assets/images/icons/collections/dark-edit-icon.svg";
import { ReactComponent as SaveIcon } from "assets/images/icons/save-icon.svg";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import DraggableIcon from "assets/images/icons/draggable-icon.png";
import DeleteIcon from "assets/images/icons/collections/delete-icon.svg";
import ReportIcon from "assets/images/icons/reports/report-icon.svg";
import ContentEditable from "react-contenteditable";
import XSelect from "components/XSelect";

const initialBlock = [
  {
    id: uuidv4(),
    html: "",
    tag: "p",
    type: "editor",
    isHovered: false,
    isOpenedAdditionalMenu: false,
  },
];

export const TextEditor = () => {
  const [controller] = useXplainableController();
  const { globalImage, globalColor, darkMode } = controller;
  const countRef = useRef(0);

  const { partitions, selectedPartition, handlePartitionChange } = useModel();
  const { activeStep, setActiveStep } = useApp();
  const { activeWorkspace, viewState } = useApiKey();
  const { logout } = useAuth0();

  const navigate = useNavigate();
  const state = useLocation()?.state;
  console.log("The state is", state)
  const searchParams = new URLSearchParams(location.search);
  const reportId = searchParams.get("report_id");

  const { createReportVersionMutation } = useReportMutation();
  const { data, isLoading, isFetching, error } = useReportsVersionQuery(
    state?.id || reportId,
    logout,
    activeStep
  );
  const { data: userData } = useUsersQuery({ activeWorkspace, logout });

  const [blocks, setBlocks] = useState(initialBlock);
  const [activeBlockId, setActiveBlockId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [threshold, setThreshold] = useState(0.5);
  const [metrics, setMetrics] = useState();

  countRef.current = countRef.current + 1;

  useEffect(() => {
    if (!error) return;

    if (error.response.status === 403) {
      navigate("/");
    }
  }, [error]);

  useEffect(() => {
    if (reportId) {
      setActiveStep(1);
    }
  }, [reportId]);

  useEffect(() => {
    if (data && reportId && data.data.length > 0) {
      setBlocks(data.data[data.data.length - 1].data[0].blocks);
    }
  }, [data, reportId]);

  const addBlockHandler = (currentBlock) => {
    const newBlock = {
      id: uuidv4(),
      html: "",
      tag: "p",
      type: "editor",
      isHovered: false,
      isOpenedAdditionalMenu: false,
    };

    setBlocks((prevBlocks) => {
      const index = prevBlocks.findIndex((b) => b.id === currentBlock.id);
      const updatedBlocks = [...prevBlocks];
      updatedBlocks.splice(index + 1, 0, newBlock);

      return updatedBlocks;
    });

    setActiveBlockId(newBlock.id);
  };

  const deleteBlockHandler = (currentBlock) => {
    const previousBlock = currentBlock.ref.parentNode.parentNode.previousElementSibling;

    if (previousBlock) {
      setBlocks((prevBlocks) => {
        const index = prevBlocks.findIndex((b) => b.id === currentBlock.id);
        const updatedBlocks = [...prevBlocks];
        updatedBlocks.splice(index, 1);
        return updatedBlocks;
      });
    }
  };

  const openAdditionalMenu = (blockId, isOpenedAdditionalMenu) => {
    setBlocks((prevBlocks) =>
      prevBlocks.map((block) =>
        block.id === blockId ? { ...block, isOpenedAdditionalMenu } : block
      )
    );
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setBlocks(items);
  };

  const handleDelete = (e, blockId, isOpenedAdditionalMenu) => {
    if (blocks.length === 1) {
      setBlocks([
        {
          id: uuidv4(),
          html: "",
          tag: "p",
          type: "editor",
          isHovered: false,
          isOpenedAdditionalMenu: false,
        },
      ]);
      openAdditionalMenu(blockId, isOpenedAdditionalMenu);
      return;
    }

    setBlocks((prevState) => {
      const filteredBlocks = prevState.filter((block) => block.id !== blockId);

      return filteredBlocks;
    });
    openAdditionalMenu(blockId, isOpenedAdditionalMenu);
  };

  const handleBlockHover = (blockId, isHovered) => {
    setBlocks((prevBlocks) =>
      prevBlocks.map((block) => (block.id === blockId ? { ...block, isHovered } : block))
    );
  };

  const handleSaveButton = () => {
    const reportData = {
      data: [
        {
          blocks,
        },
      ],
    };

    createReportVersionMutation([state.id || reportId, JSON.stringify(reportData)],
      {
        onSuccess: (data) => {
          setActiveStep(1);

          if (isEditing) {
            setIsEditing(false);
            queryClient.invalidateQueries(QUERY_CONSTANTS.REPORT_VERSION);
          }
        },
      }
    );
  };

  const handleEditButton = () => {
    setIsEditing(true);
  };

  const handleOpen = async () => {
    setIsModalOpen(true);
  };

  const handleNewRowClick = (e) => {
    if (e.target.tagName !== "DIV") {
      return;
    }

    const element = document.getElementById("wrapper").lastElementChild;
    const rect = element.getBoundingClientRect();
    const mouseY = e.clientY;
    const elementY = rect.y;

    if (mouseY > elementY) {
      const newBlock = {
        id: uuidv4(),
        html: "",
        tag: "p",
        type: "editor",
        isHovered: false,
        isOpenedAdditionalMenu: false,
      };

      setBlocks((prevBlocks) => {
        return [...prevBlocks, newBlock];
      });

      setActiveBlockId(newBlock.id);
    }
  };

  const renderButton = () => {
    if (activeStep === 1 && viewState === "creator" && isEditing) {
      return (
        <XBox onClick={handleSaveButton} display="flex" sx={{ cursor: "pointer" }}>
          <XImg alignItems="center">
            <SaveIcon />
          </XImg>
        </XBox>
      );
    }

    if (activeStep === 1 && viewState === "creator" && !isEditing) {
      return (
        <XBox onClick={handleEditButton} display="flex" sx={{ cursor: "pointer" }}>
          <XImg alignItems="center">
            <DarkEditIcon />
          </XImg>
        </XBox>
      );
    }

    if (activeStep === 0) {
      return (
        <XBox onClick={handleSaveButton} display="flex" sx={{ cursor: "pointer" }}>
          <XImg alignItems="center">
            <SaveIcon />
          </XImg>
        </XBox>
      );
    }

    return null;
  };

  if (isLoading && activeStep === 1) {
    return (
      <XBox
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingSpinner size={100} animationType="pulse" />
      </XBox>
    );
  }

  const renderPartitionSelect = () => {
    if (
      (partitions.length > 0 && partitions[0].partition_value !== "__dataset__") ||
      partitions.length > 1
    ) {
      return (
        <XBox display="flex" flexDirection="column" mx={1}>
          <XBox display="flex" justifyContent="space-between">
            <XTypography variant="overline">Partitions</XTypography>
          </XBox>
          <XSelect
            value={selectedPartition}
            onChange={handlePartitionChange}
            fullWidth
            placeholder="Select Partition"
            options={partitions.map((partition) => ({
              value: partition.partition_id,
              label: partition.partition_value,
            }))}
          />
        </XBox>
      );
    }
  };

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={"TextEditor"}
        description={"Prescriptive recommendations for optimising tasks in collective batches."}
        icon={"ni ni-atom"}
        rhsElement={
          <XBox
            display="flex"
            flexDirection="column"
            width="100%"
            // onClick={handleNewRowClick}
            id="page"
          >
            <XBox
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              mb={2}
            >
              <XBox sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <XBox
                  display="flex"
                  alignItems="center"
                  gap={1}
                  px="12px"
                  py={1}
                  bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
                  sx={{ borderRadius: "24px" }}
                >
                  <XBox component="img" src={ReportIcon} />
                </XBox>

                <XBox display="flex" flexDirection="column" gap={0.5}>
                  <XTypography variant="button" fontSize="24px">
                    {state ? state.reportName : ""}
                  </XTypography>
                  <XTypography variant="button" fontWeight="regular" color="secondary">
                    {state ? state.reportDescription : ""}
                  </XTypography>
                </XBox>
              </XBox>
              <XBox display="flex" gap={2} alignItems="end">
                {renderPartitionSelect()}

                <XBox display="flex" alignItems="center" gap={2}>
                  {renderButton()}

                  <Tooltip title="External report sharing coming soon.">
                    <XBox>
                      <XButton
                        variant="outlined"
                        disabled={true}
                        sx={{
                          background: controller.darkMode ? "#1D1B1B" : "#F7F7F8",
                          borderColor: controller.darkMode ? "#1D1B1B" : "#F7F7F8",
                          color: controller.darkMode ? "white !important" : "black !important",
                        }}
                        onClick={handleOpen}
                      >
                        Share report
                      </XButton>
                    </XBox>
                  </Tooltip>
                </XBox>
              </XBox>
            </XBox>
            <XDivider />
            <XBox p={4}>
              {isFetching && activeStep === 1 ? (
                <XBox
                  minHeight="500px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <LoadingSpinner size={50} animationType="pulse" />
                </XBox>
              ) : activeStep === 1 && data && data?.data.length >= 0 && !isEditing ? (
                <XBox display="flex" flexDirection="column" gap={1} mt={1}>
                  {data?.data[data?.data?.length - 1]?.data[0]?.blocks.map((row, index) => {
                    if (row.tag === REPORT_COMPONENTS_KEYS.COLUMNS) {
                      return (
                        <XBox display="flex" gap="8px" justifyContent="space-between">
                          {Object.values(row.columns).map((column, index) => (
                            <>
                              <XBox
                                width="100%"
                                key={index}
                                p={1}
                                display="flex"
                                flexDirection="column"
                                gap="4px"
                              >
                                {column.map((item) => {
                                  if (
                                    components[item.tag] &&
                                    (item.tag === REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT ||
                                      item.tag === REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX ||
                                      item.tag === REPORT_COMPONENTS_KEYS.METRICS)
                                  ) {
                                    return (
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <XBox>
                                            {components[item.tag](
                                              threshold,
                                              setThreshold,
                                              setMetrics,
                                              metrics
                                            )}
                                          </XBox>
                                        </Grid>
                                      </Grid>
                                    );
                                  }

                                  if (components[item.tag]) {
                                    return (
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <XBox>{components[item.tag]()}</XBox>
                                        </Grid>
                                      </Grid>
                                    );
                                  }

                                  return (
                                    <XBox key={index}>
                                      <ContentEditable
                                        html={item.html}
                                        tagName={item.tag}
                                        disabled
                                        placeholder="Type a page title..."
                                        style={{
                                          color: darkMode ? "white" : "black",
                                          background: darkMode ? "#262525" : "white",
                                        }}
                                      />
                                    </XBox>
                                  );
                                })}
                              </XBox>
                              {index === 0 && (
                                <XBox width="1px" sx={{ background: "#EAEAEA" }}></XBox>
                              )}
                            </>
                          ))}
                        </XBox>
                      );
                    }

                    if (
                      components[row.tag] &&
                      (row.tag === REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT ||
                        row.tag === REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX ||
                        row.tag === REPORT_COMPONENTS_KEYS.METRICS)
                    ) {
                      return (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <XBox>
                              {components[row.tag](threshold, setThreshold, setMetrics, metrics)}
                            </XBox>
                          </Grid>
                        </Grid>
                      );
                    }

                    if (components[row.tag]) {
                      return (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <XBox>
                              {components[row.tag](
                                data?.data[data.data.length - 1].data[0].blocks,
                                setBlocks,
                                row.id
                              )}
                            </XBox>
                          </Grid>
                        </Grid>
                      );
                    }

                    return (
                      <XBox key={index}>
                        <ContentEditable
                          html={row.html}
                          tagName={row.tag}
                          disabled
                          placeholder="Type a page title..."
                          style={{
                            color: darkMode ? "white" : "black",
                            backgroundColor: darkMode ? "#262525 !important" : "white",
                          }}
                        />
                      </XBox>
                    );
                  })}
                </XBox>
              ) : (
                <XBox mt={4}>
                  <XBox className="Page" display="flex" flexDirection="column" gap={0.5}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId={"items"}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps} id="wrapper">
                            {blocks.map((block, index) => {
                              return (
                                <Draggable key={block.id} draggableId={block.id} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      onMouseEnter={() => handleBlockHover(block.id, true)}
                                      onMouseLeave={() => handleBlockHover(block.id, false)}
                                    >
                                      <XBox
                                        display="flex"
                                        width="100%"
                                        position="relative"
                                        gap={1}
                                        alignItems={components[block.tag] ? "flex-end" : "center"}
                                        mb={1}
                                      >
                                        <ClickAwayListener
                                          onClickAway={() => openAdditionalMenu(block.id, false)}
                                        >
                                          <XBox>
                                            <XBox
                                              position="absolute"
                                              left="-50px"
                                              bottom="0"
                                              sx={{
                                                padding: "10px",
                                                background: "#fff",
                                                borderRadius: "5px",
                                                zIndex: "9999",
                                                transition: "background-color 0.3s",
                                                opacity: block.isOpenedAdditionalMenu ? "1" : "0",
                                                transition: "all 0.3s",
                                                "&:hover": {
                                                  background: "#f0f0f0",
                                                },
                                                boxShadow:
                                                  "0 0 0 1px rgba(15,15,15,.05), 0 3px 6px rgba(15,15,15,.1), 0 9px 24px rgba(15,15,15,.2)",
                                              }}
                                              onClick={(e) => handleDelete(e, block.id, false)}
                                            >
                                              <XBox display="flex" gap={1}>
                                                <XBox component="img" src={DeleteIcon}></XBox>
                                              </XBox>
                                            </XBox>
                                            <XBox
                                              {...provided.dragHandleProps}
                                              onMouseUp={(e) => openAdditionalMenu(block.id, true)}
                                              sx={{
                                                opacity: block.isHovered ? "1" : "0",
                                                transition: "all 0.3s",
                                              }}
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <XBox
                                                component="img"
                                                src={DraggableIcon}
                                                height="15px"
                                              />
                                            </XBox>
                                          </XBox>
                                        </ClickAwayListener>
                                        <EditableBlock
                                          tagName={block.tag}
                                          html={block.html}
                                          onChangeBlocks={setBlocks}
                                          blocks={blocks}
                                          id={block.id}
                                          addBlock={addBlockHandler}
                                          deleteBlock={deleteBlockHandler}
                                          activeBlockId={activeBlockId}
                                          threshold={threshold}
                                          setThreshold={setThreshold}
                                          isEditing={isEditing}
                                          setMetrics={setMetrics}
                                          metrics={metrics}
                                        />
                                      </XBox>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </XBox>
                </XBox>
              )}

              <ShareReport
                isModalOpen={isModalOpen}
                users={userData?.data}
                setIsModalOpen={setIsModalOpen}
              />
            </XBox>
          </XBox>
        }
      />
    </DashboardLayout>
  );
};
