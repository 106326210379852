import { Grid } from "@mui/material";
import React, { useContext } from "react";
import ProgressLineChart from "shared/Charts/ProgressLineChart";
import DeploymentContext from "../deployment-context";

export const Monitoring = () => {
  const { deployment_id, model_name } = useContext(DeploymentContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} xl={12} mt={3}>
        <ProgressLineChart
          icon="group"
          shadow={false}
          title={model_name + " - API Calls"}
          color={"xpblue"}
          height={"500px"}
          event="inference_server_predict"
          deployment_id={deployment_id}
          metric="count"
          stack={true}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={12} >
        <ProgressLineChart
          icon="group"
          shadow={false}
          title={model_name + " - Average Server Latency"}
          color={"xppurple"}
          height={"500px"}
          event="inference_server_predict"
          deployment_id={deployment_id}
          group="average"
          metric="avg_latency"
          stack={true}
        />
      </Grid>
    </Grid>
  );
};
